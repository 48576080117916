import React from 'react'
import { ReactComponent as Glass } from "../../../assets/images/LandingPage/requ-submission/Holder.svg";
import { Typography } from '@mui/material';
export const  Boxs=()=> {
  return (
    <div style={{display:'flex',flexDirection:'row',padding:'20px', marginLeft: '69px', marginTop: '-45px'}}>
        <div style={{position:'relative'}}>
            <div style={{position:'absolute', bottom:'50px',left:'9px',alignItems:'center',textAlign:'center' , padding:'2px', margin:'2px' }}>
                <Typography  variant='h3' color='#fff' sx={{fontSize:'22px', fontWeight:'600'}}>980+</Typography>
                <Typography variant='h6' color='#fff' sx={{marginTop:'14px', fontSize:'14px', fontWeight:'200'}}>Submission Active Now </Typography>
                
            </div>
            <Glass width='170' height='160'/>
        </div>
        <hr/>
        <div style={{position:'relative'}}>
            <div style={{position:'absolute',bottom:'50px',left:'9px',alignItems:'center',marginLeft:'10px'}}>
                <Typography  variant='h3' color='#fff' sx={{fontSize:'22px', fontWeight:'600',textAlign:'center'}}>1000+</Typography>
                <Typography variant='h6' color='#fff' sx={{marginTop:'14px', fontSize:'14px', fontWeight:'200'}}>Users and growing </Typography>
                
            </div>
            <Glass width='170' height='160'/>


        </div>
        <hr/>
        <div style={{position:'relative'}}>
            <div style={{position:'absolute',bottom:'50px',left:'9px',alignItems:'center', marginLeft:'10px'}}>
                <Typography  variant='h3' color='#fff' sx={{fontSize:'22px', fontWeight:'600' , textAlign:'center'}}>570+</Typography>
                <Typography variant='h6' color='#fff' sx={{marginTop:'14px', fontSize:'14px',fontWeight:'200'}}>Requisitions Posted </Typography>
                
            </div>
            <Glass width='170' height='160'/>


        </div>
        <hr/>
        <div style={{position:'relative'}}>
            <div style={{position:'absolute',bottom:'50px',left:'9px',alignItems:'center',textAlign:'center'}}>
                <Typography  variant='h3' color='#fff' sx={{fontSize:'22px', fontWeight:'600'}}>100,000</Typography>
                <Typography variant='h6' color='#fff' sx={{marginTop:'14px', fontSize:'14px', fontWeight:'200'}}>CV Database Available</Typography>
                
            </div>
            <Glass width='170' height='160'/>


        </div>
        <hr/>
      
    </div>
  )
}

export default Boxs
