import styled from "@emotion/styled";
import {
  Box,
  Chip,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useAppSelector } from "core/store";
import { theme, themeColors } from "core/Theming/theme";
import { CustomTabPanel } from "../CommonComponents/Tabs";
import { useEffect, useState } from "react";
import {
  DataGrid,
} from "@mui/x-data-grid";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ReactComponent as Filtericon } from "../../../assets/icons/vendorFilter.svg";
import { ReactComponent as RmpHeadericon } from "../../../assets/icons/rmpHeaderIcon.svg";

import {
  EditOrMoveIntOrNotInterestedReqResponceModel,
  RMPdataTableModel,
  VenderAllRequisitionModel,
} from "api/resources/modals";
import {
  useEditRequesitionSingle,
  useMoveRequesitionSingle,
  useRmpDataAll,
  useRmpIntrestedData,
  useRmpNotIntrestedData,
} from "pages/hooks";
import { Link, useNavigate } from "react-router-dom";
import { CommonModel } from "../CommonComponents/Model";
import { toast } from "react-toastify";
import { RMP_TABLE_TABS } from "./constants/VendorRmpDetails";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";

export type requesitionType = "interested" | "not-interested";

export type APITYPES = "EDIT" | "MOVE";

const Rmp = () => {
  const navigate = useNavigate();
  const vendorId: string =
    useAppSelector((state) => state.auth?.userInfo?.user_id) || "";

  const [apiType, setApiType] = useState<APITYPES>("MOVE");
  const [isBulk, setBulk] = useState<boolean>(false);
  const {
    rmpAllData = [],
    refetch: refetchAllList,
    isSuccess: allDataSuccessState,
  } = useRmpDataAll(vendorId);
  const {
    rmpIntrestedData = [],
    refetch: refetchIntrestedList,
    isSuccess: interestedSuccessState,
  } = useRmpIntrestedData(vendorId);
  const {
    rmpNotIntrestedData = [],
    refetch: refetchNotIntrestedList,
    isSuccess: notInterestedSuccessState,
  } = useRmpNotIntrestedData(vendorId);

  const moveRequisition = useMoveRequesitionSingle({
    onError: () => setRmpDataForTable(),
    onSuccess: () => setRmpDataForTable(),
  });

  const editRequisition = useEditRequesitionSingle({
    onError: () => setRmpDataForTable(),
    onSuccess: () => setRmpDataForTable(),
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const [requesitionsData, setRequesitionsData] = useState<RMPdataTableModel[]>(
    []
  );

  const [selectedRequesitions, setSelectedRequesitions] = useState<
    RMPdataTableModel[]
  >([]);

  const [selectedRequesition, setSelectedRequesition] = useState<string>("");
  const [modelOpen, setModelOpen] = useState<boolean>(false);
  const [modelType, setModelType] = useState<requesitionType>("not-interested");

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const transformData = (
    data: VenderAllRequisitionModel[] | []
  ): RMPdataTableModel[] | [] => {
    const transformedData: RMPdataTableModel[] | [] = data
      .filter(
        (item) =>
          Object.keys(item).length !== 0 &&
          item?.id !== undefined &&
          item?.id !== null
      )
      .map((item, index) => ({
        id: index,
        job_title: item?.rfq_details?.job_title || "",
        job_type: item?.rfq_details?.job_type || "",
        primary_skills: item?.rfq_details?.primary_skills || "",
        secondary_skills: item?.rfq_details?.secondary_skills || "",
        rfq_closing_date: item?.rfq_details?.rfq_closing_date || "",
        bill_rate: item?.rfq_details?.rate_info?.bill_rate || "",
        rfq_id: item?.rfq_details?.rfq_id || "",
        commission: item?.rfq_details?.rate_info?.commission || "",
        visaType: item?.rfq_details?.visa_type || "",
        employeeLevel: item?.rfq_details?.employee_level || "",
      }));
    return transformedData;
  };

  const headers = [
    {
      title: "Requisition ID",
      name: "rfqId",
      cellWidth: "200px",
      filterType: "number",
      filter: true,
      show: true,
      customComponent: (row: RMPdataTableModel) => {
        const maxLength = 25;
        const truncatedRfqId = row.rfq_id.length > maxLength ? `${row.rfq_id.substring(0, maxLength)}...` : row.rfq_id;
        return (
          <Tooltip title={truncatedRfqId} arrow>
            <Jobtitle>
              <Link to={`/vendor/rmp/${row.rfq_id}/submission-details`}>
                {truncatedRfqId}
              </Link>
            </Jobtitle>
          </Tooltip>
        );
      },
    },
    {
      title: "Job Title",
      name: "job_title",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        const maxLength = 25;
        const truncatedJobTitle = row.job_title.length > maxLength ? `${row.job_title.substring(0, maxLength)}...` : row.job_title;
        return (
          <Tooltip title={truncatedJobTitle} arrow>
            <Jobtitle>
              <Link to={`/vendor/rmp/${row.rfq_id}/submission-details`}>
                {truncatedJobTitle}
              </Link>
            </Jobtitle>
          </Tooltip>
        );
      },
    },
    {
      title: "Job Type",
      name: "job_type",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        return (
          <>
            <Stack direction="row" spacing={1} mt={0}>
              <Typeofjobs>{row.job_type}</Typeofjobs>
            </Stack>
          </>
        );
      },
    },
    {
      title: "Primary Skills",
      name: "primary_skills",
      cellWidth: "300px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        const maxSkillsToShow = 1;
        const { primary_skills = [] } = row;
        const visibleSkills = primary_skills.slice(0, maxSkillsToShow);
        const hiddenSkills = primary_skills.slice(maxSkillsToShow);
        return (
          <Stack direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Skill label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Skill label="..." />
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      title: "Secondary Skills",
      name: "secondary_skills",
      cellWidth: "300px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        const maxSkillsToShow = 1;
        const { secondary_skills = [] } = row;
        const visibleSkills = secondary_skills.slice(0, maxSkillsToShow);
        const hiddenSkills = secondary_skills.slice(maxSkillsToShow);
        return (
          <Stack direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Secondaryskill label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Secondaryskill label="..." />
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      title: "Requisition Close Date",
      name: "RFR_closing_date",
      cellWidth: "250px",
      filterType: "date",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        const cellValue = row.rfq_closing_date;
        let displayText;
        let cellStyle = { color: themeColors.blackGray };
        if (cellValue === null || cellValue === "") {
          displayText = "-";
        } else if (cellValue === "expired") {
          displayText = "Expired";
          cellStyle.color = themeColors.error;
        }
        displayText = cellValue;
        return (
          <>
            <Stack justifyContent={'center'} direction="row" spacing={1} mt={0}>
              <p style={cellStyle}>{displayText}</p>
            </Stack>
          </>
        );
      },
    },
    {
      title: "Rate Card",
      name: "bill_rate",
      cellWidth: "200px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        return (
          <Stack direction="row" spacing={1} mt={0}>
            <p>{row.bill_rate}</p>
          </Stack>
        );
      },
    },
    {
      title: "Commission",
      name: "commission",
      cellWidth: "150px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        return (
          <Stack direction="row" spacing={1} mt={0}>
            <p>{row.commission}</p>
          </Stack>
        );
      },
    },
    {
      title: "visa Type",
      name: "visaType",
      cellWidth: "150px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        const maxLength = 11;
        const fullText = row.visaType.join(' ');
        const truncatedText = fullText.length > maxLength ? `${fullText.substring(0, maxLength)}...` : fullText;
        return (
          <Tooltip title={fullText} arrow>
            <Typography variant="body2" noWrap>
              {truncatedText}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      title: "Employee Level",
      name: "employeeLevel",
      cellWidth: "150px",
      filterType: "text",
      filter: true,
      customComponent: (row: RMPdataTableModel) => {
        return (
          <Stack direction="row" spacing={1} mt={0}>
            <p>{row.employeeLevel}</p>
          </Stack>
        );
      },
    },
  ];

  const actionItmes = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: RMPdataTableModel) => { },
      customActionComponent: (row: RMPdataTableModel) => {
        return (
          <ActinItemDiv>
            {selectedTab === 0 ? (
              <>
                <LightToolTip
                  textColor={themeColors.success}
                  title="interested"
                  arrow
                >
                  <AcceptedIcon
                    onClick={() => moveToSigle(row.rfq_id, "interested", "MOVE")}
                  />
                </LightToolTip>
                <LightToolTip
                  textColor={themeColors.error}
                  title="not-interested"
                  arrow
                >
                  <RejectIcon
                    onClick={() => moveToSigle(row.rfq_id, "not-interested", "MOVE")}
                  />
                </LightToolTip>
              </>
            ) : selectedTab === 1 ? (
              <LightToolTip
                textColor={themeColors.error}
                title="not-interested"
                arrow
              >
                <RejectIcon
                  onClick={() => moveToSigle(row.rfq_id, "not-interested", "EDIT")}
                />
              </LightToolTip>
            ) : (
              <LightToolTip
                textColor={themeColors.success}
                title="interested"
                arrow
              >
                <AcceptedIcon
                  onClick={() => moveToSigle(row.rfq_id, "interested", "EDIT")}
                />
              </LightToolTip>
            )}
            <div
              onClick={() =>
                navigate(`/vendor/rmp/${row.rfq_id}/submission-details`)
              }
            >
              <SendSubmitionIcon
                key={"createSubmission"}
                label={"Create Submission"}
              />
            </div>
          </ActinItemDiv>
        );
      },
    },
  ];

  const handleDoBulk = (selectedItems: RMPdataTableModel[]) => {
    setSelectedRequesitions(selectedItems);
  };

  const setRmpDataForTable = () => {
    switch (selectedTab) {
      case 0:
        setRequesitionsData(transformData(rmpAllData));
        break;
      case 1:
        setRequesitionsData(transformData(rmpIntrestedData));
        break;
      case 2:
        setRequesitionsData(transformData(rmpNotIntrestedData));
        break;
      default:
        setRequesitionsData([]);
        break;
    }
  };

  useEffect(() => {
    if (
      allDataSuccessState ||
      notInterestedSuccessState ||
      interestedSuccessState
    ) {
      setRmpDataForTable();
    }
  }, [
    allDataSuccessState,
    notInterestedSuccessState,
    interestedSuccessState,
    selectedTab,
  ]);

  useEffect(() => {
    if (selectedTab === 0) {
      refetchAllList();
    } else if (selectedTab === 1) {
      refetchIntrestedList();
    } else if (selectedTab === 2) {
      refetchNotIntrestedList();
    }
  }, [selectedTab]);

  const moveToSigle = (
    rfqId: string,
    modelType: requesitionType,
    apiType: APITYPES
  ) => {
    setApiType(apiType);
    setBulk(false);
    setModelType(modelType);
    setSelectedRequesition(rfqId);
    setModelOpen(true);
  };

  const moveToBulk = (modelType: requesitionType, apiType: APITYPES) => {
    setApiType(apiType);
    setBulk(true);
    setModelType(modelType);
    setModelOpen(true);
  };

  const handleModelConform = async () => {
    if (isBulk) {
      const allBulkActions: Promise<EditOrMoveIntOrNotInterestedReqResponceModel>[] =
        [];
      switch (apiType) {
        case "EDIT":
          if (
            Array.isArray(selectedRequesitions) &&
            selectedRequesitions.length > 0
          ) {
            Array.isArray(selectedRequesitions) &&
              selectedRequesitions.map((eachRequesition: RMPdataTableModel) => {
                allBulkActions.push(
                  editRequisition.mutateAsync({
                    userID: vendorId,
                    rfq_id: eachRequesition.rfq_id,
                    status: modelType,
                  })
                );
              });
            Promise.allSettled(allBulkActions)
              .then((result) => {
                let fullFilledRequests = 0;
                let notFullFilled = 0;
                result.map((eachResult) =>
                  eachResult.status === "fulfilled"
                    ? (fullFilledRequests += 1)
                    : (notFullFilled += 1)
                );
                if (fullFilledRequests)
                  toast.success(
                    `${fullFilledRequests} Requisition's Moved to ${modelType} successfully`
                  );
                if (notFullFilled) {
                  toast.error(
                    `${notFullFilled} Requisition's not Moved to ${modelType}`
                  );
                }
              })
              .catch((err) => {
                toast.error(`Requestion not moved. please try again.!`);
              });
          } else toast.error(`Please select at least one Requesition.!`);
          break;
        case "MOVE":
          if (
            Array.isArray(selectedRequesitions) &&
            selectedRequesitions.length > 0
          ) {
            Array.isArray(selectedRequesitions) &&
              selectedRequesitions.map((eachRequesition: RMPdataTableModel) => {
                allBulkActions.push(
                  moveRequisition.mutateAsync({
                    userID: vendorId,
                    rfq_id: eachRequesition.rfq_id,
                    status: modelType,
                  })
                );
              });
            Promise.allSettled(allBulkActions)
              .then((result) => {
                let fullFilledRequests = 0;
                let notFullFilled = 0;
                result.map((eachResult) =>
                  eachResult.status === "fulfilled"
                    ? (fullFilledRequests += 1)
                    : (notFullFilled += 1)
                );
                if (fullFilledRequests)
                  toast.success(
                    `${fullFilledRequests} Requisition's Moved to ${modelType} successfully`
                  );
                if (notFullFilled)
                  toast.error(
                    `${notFullFilled} Requisition's not Moved to ${modelType}`
                  );
              })
              .catch((err) => {
                toast.error(`Requestion not moved. please try again.!`);
              });
          } else toast.error(`Please select at least one Requesition.!`);
          break;
        default:
          toast.error(`something wrong.!`);
      }
    } else {
      switch (apiType) {
        case "EDIT":
          if (selectedRequesition)
            editRequisition
              .mutateAsync({
                userID: vendorId,
                rfq_id: selectedRequesition,
                status: modelType,
              })
              .then(() => {
                toast.success(`Requisition Moved to ${modelType} successfully`);
              });
          else toast.error(`Please select Requesition.!`);
          setSelectedRequesition("");
          break;
        case "MOVE":
          if (selectedRequesition)
            moveRequisition
              .mutateAsync({
                userID: vendorId,
                rfq_id: selectedRequesition,
                status: modelType,
              })
              .then(() => {
                toast.success(`Requisition Moved to ${modelType} successfully`);
              });
          else toast.error(`Please select Requesition.!`);
          setSelectedRequesition("");
          break;
        default:
          toast.error(`something wrong.!`);
      }
    }
    setModelOpen((previewsStatus) => !previewsStatus);
    setSelectedRequesitions([]);
  };

  return (
    <MainContent>
      <HeadingContainer>
        <HeadingTextSx variant="h4" color={themeColors.purpleDark}>
        <RmpHeadericon /> Requesition Market Place (RMP)
          <CustomToolTip
            placement="bottom-start"
            title="Requisition means the user, as an employer, is creating the job and requesting quotations for job seeker profiles. So here, you are raising a request for quotations."
            arrow
          >
            <InfoOutlinedIcon />
          </CustomToolTip>
        </HeadingTextSx>
      </HeadingContainer>
      <MiddleContainer>
        <CustomTabPanel
          selectedTab={selectedTab}
          changeTabs={handleTabChange}
          labels={RMP_TABLE_TABS}
        />
        <MainGridContainer>
          <LeftsiteContainer>
            <Leftsitecontent>
              <LeftcontentText>
                {`${selectedRequesitions?.length || 0} of ${requesitionsData.length
                  } Selected`}
              </LeftcontentText>

              {selectedTab == 0 ? (
                <>
                  <LightToolTip
                    textColor={themeColors.success}
                    title="interested"
                    arrow
                  >
                    <AcceptedIcon
                      onClick={() => {
                        moveToBulk("interested", "MOVE");
                      }}
                    />
                  </LightToolTip>
                  <LightToolTip
                    textColor={themeColors.error}
                    title="not-interested"
                    arrow
                  >
                    <RejectIcon
                      onClick={() => {
                        moveToBulk("not-interested", "MOVE");
                      }}
                    />
                  </LightToolTip>
                </>
              ) : selectedTab == 1 ? (
                <LightToolTip
                  textColor={themeColors.error}
                  title="not-interested"
                  arrow
                >
                  <RejectIcon
                    onClick={() => {
                      moveToBulk("not-interested", "EDIT");
                    }}
                  />
                </LightToolTip>
              ) : (
                <LightToolTip
                  textColor={themeColors.success}
                  title="interested"
                  arrow
                >
                  <AcceptedIcon
                    onClick={() => {
                      moveToBulk("interested", "EDIT");
                    }}
                  />
                </LightToolTip>
              )}
            </Leftsitecontent>
            <RightSiteICon>
              <FilterIcon />
            </RightSiteICon>
          </LeftsiteContainer>
          <Maingridtable>
            <TableContainer>
              <ReusableTable
                headers={headers}
                data={requesitionsData}
                itemsPerPage={10}
                spinner={
                  !allDataSuccessState ||
                  !interestedSuccessState ||
                  !notInterestedSuccessState
                }
                handleDoBulk={handleDoBulk}
                checkBox={true}
                headerColor={themeColors.purpleLight}
                inputBorder={themeColors.purpleDark}
                actions={actionItmes}
                actionsCellWidth={selectedTab === 0 ? "230px" : "200px"}
              />
            </TableContainer>
          </Maingridtable>
        </MainGridContainer>
      </MiddleContainer>
      <CommonModel
        modelType={modelType}
        handleClose={() => {
          setModelOpen((previewsStatus) => !previewsStatus);
        }}
        handleConfirm={handleModelConform}
        isOpen={modelOpen}
        id={selectedRequesition}
      />
    </MainContent>
  );
};

export const MainContent = styled(Box)({
  overflow: "hidden",
  position: "relative",
  width: "100%",
  padding: "20px",
});

export const HeadingContainer = styled(Box)({
  width: "100%",
  display: "flex",
  height: "64px",
  padding: "2px 20px",
  border: `1px solid ${themeColors.primaryColorLight}`,
  borderRadius: "8px",
  marginBottom: "16px",
  background: themeColors.primaryColorLight,
});

export const HeadingTextSx = styled(Typography)({
  display: "flex",
  gap:"8px",
  justifyContent: "center",
  alignItems: "center",
});

export const MiddleContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  marginTop: "16px",
});

export const MainGridContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0px 7px",
  border: `1px solid ${themeColors.purpleDark} !important`,
  borderRadius: "8px",
  boxShadow: `0px 0px 4px 0px ${themeColors.purpleDark}`,
});

export const LeftsiteContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "0px 7px",
});

export const Leftsitecontent = styled(Box)({
  display: "flex",
  gap: "8px",
  justifyContent: "flex-start",
  alignItems: "center",
  height: "42px",
});

export const RightSiteICon = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const FilterIcon = styled(Filtericon)({
  cursor: "pointer",
  width: "24px",
  height: "24px",
  marginRight: "24px",
});

export const LeftcontentText = styled(`p`)({
  marginLeft: "15px",
  color: themeColors.grey,
  marginRight: "15px",
});

interface LightToolTipProps extends TooltipProps {
  textColor?: string;
}

const LightToolTip = styled(
  ({
    className,
    textColor = themeColors.grey,
    ...props
  }: LightToolTipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, textColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "6px",
    color: textColor,
    boxShadow: `0 0 0.45rem ${themeColors.primaryAlt}`,
    fontSize: 12,
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.common.white,
  },
}));

const RejectIcon = styled(CancelIcon)({
  color: themeColors.error,
  cursor: "pointer",
});

const AcceptedIcon = styled(CheckCircleIcon)({
  color: themeColors.success,
  cursor: "pointer",
});

export const Maingridtable = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "100%",
});

export const GridTable = styled(DataGrid)({
  width: "100%",
  minHeight: "300px",
  border: "none",
  "& .MuiDataGrid-main": {
    flexGrow: 1,
    position: "relative",
  },
  "& .MuiDataGrid-container--top [role=row], & .MuiDataGrid-container--bottom [role=row]":
  {
    background: themeColors.primaryColorLight,
  },
  "& .MuiDataGrid-menuIcon": {
    visibility: "visible",
    width: "auto",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    marginRight: "8px",
  },

  "& .MuiDataGrid-columnHeader": {
    position: "relative",
    paddingRight: "40px",
    display: "flex",
    alignItems: "center",
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flex: 1,
    maxWidth: "100%",
  },

  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.background.paper,
  },

  "& .MuiDataGrid-cellCheckbox": {
    visibility: "visible",
    height: 56,
    padding: "0",
    maxWidth: "80px",
  },

  "& .MuiDataGrid-checkbox": {
    visibility: "visible",
    height: 56,
    padding: "0",
    maxWidth: "120px",
  },

  "& .MuiDataGrid-columnHeaderCheckbox": {
    paddingRight: "0",
    width: "100px",
  },
});

export const TableContainer = styled(`div`)({
  overflowX: "scroll",
});

export const ActinItemDiv = styled(`div`)({
  margin: "5.6px 0px",
  display: "flex",
  gap: "8px",
  alignItems: "center",
});

const SendSubmitionIcon = styled(Chip)({
  fontWeight: "500",
  color: themeColors.white,
  backgroundColor: themeColors.purpleDark,
  cursor: "pointer",
});

const Jobtitle = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  textAlign: "center",
});

const Typeofjobs = styled(`p`)({
  textTransform: "capitalize",
});

export const Skill = styled(Chip)({
  background: themeColors.primaryLight,
  color: themeColors.primaryDark,
  fontWeight: "500",
});

const Secondaryskill = styled(Chip)({
  background: themeColors.purpleLight,
  color: themeColors.purpleDark,
  fontWeight: "500",
});

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 680,
    left: -50,
    backgroundColor: themeColors.white,
    color: themeColors.black,
    fontSize: 12,
    border: `1px solid ${themeColors.white}`,
    borderRadius: "6px",
    boxShadow: `0 0 0.25rem ${themeColors.black}`,
  },
  [`& .MuiTooltip-arrow`]: {
    left: "50px !important",
    color: themeColors.white,
  },
});

export default Rmp;
