import React, { useState, FormEvent, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { ToastContainer, toast } from 'react-toastify';
import styled from '@emotion/styled';
import container from '../../assets/images/container.png';
import logo from '../../assets/images/logo.svg';
import TalentXchangLogo from "../../assets/images/TalentXchange Logo Sign Up.svg"
import or from '../../assets/images/or.svg';
import {
  useSignIn,
  useSignUp,
  useSignInVerifyOTP,
  useSignUpVerifyOTP,
  useSocialSignIn,
  useSocialSignUp,
  useRFQDescription,
} from 'pages/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { IResolveParams, LoginSocialGoogle } from 'reactjs-social-login';
import ReCAPTCHA from 'react-google-recaptcha';
import { ROUTES } from 'core/routes';
import { themeColors } from 'core/Theming/theme';
import Spinner from 'components/spinner/Spinner';
import { useAppSelector } from 'core/store';
import signUpIllustrtation from "assets/images/Sign Up Illustration.svg";


interface SignUpProps {
  mode: 'signup' | 'signin';
}

const SignUp: React.FC<SignUpProps> = ({ mode }) => {
  const params = useParams();
  const rfqId = params?.id
  const navigate = useNavigate();
  const userInfo = useAppSelector(store => store.auth.userInfo)
  const [isCaptchaVerified, setIsCaptchaVerified] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [OTP, setOtp] = useState<string>('');
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [jobDetails, setJobDetails] = useState<{ label: string, value: string | number | null, icon: string }[] | []>([])
  const { rfqDescription, error, refetch, isLoading } = useRFQDescription(rfqId ? rfqId : '');
  const isExpired = new Date(rfqDescription?.details?.rfq_details?.rfq_closing_date || '') > new Date()

  const { socialSignIn, isSigningIn } = useSocialSignIn({
    onError: (error) => {
      if (error.response && error.response.status === 400)
        toast.error('Email does not exist, go to signup page');
      else toast.error('An error occurred');
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate(!rfqId ? ROUTES.authUserCheck : `${ROUTES.authUserCheck}/${rfqId}`)
      }, 200)
    },
  });

  const { socialSignUp, isSigningUp } = useSocialSignUp({
    onError: (error) => {
      if (error.response && error.response.status === 400)
        toast.error('Email is already exists, go to signin page');
      else toast.error('An error occurred');
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate(!rfqId ? ROUTES.authUserCheck : `${ROUTES.authUserCheck}/${rfqId}`)
      }, 200)
    },
  });

  const { isLogging, signUp } = useSignUp({
    onError: (error) => {
      if (error.response && error.response.status === 400)
        toast.error('Email is already exists, go to signin page');
      else toast.error('An error occurred');
    },
    onSuccess: () => setIsOtpSent(true),
  });
  const { isVerifying, signUpVerifyOTP } = useSignUpVerifyOTP({
    onSuccess: () => {
      setTimeout(() => {
        navigate(!rfqId ? ROUTES.authUserCheck : `${ROUTES.authUserCheck}/${rfqId}`)
      }, 200)
    },
    onError: () => toast.error('Error verifying OTP. Please try again.'),
  });

  const { isSigning, signIn } = useSignIn({
    onError: (error) => {
      if (error.response && error.response.status === 400)
        toast.error('Email does not exist, go to signup page');
      else toast.error('An error occurred');
    },
    onSuccess: () => setIsOtpSent(true),
  });

  const { signInVerifyOTP, isSignInVerifying } = useSignInVerifyOTP({
    onSuccess: () => {
      setTimeout(() => {
        navigate(!rfqId ? ROUTES.authUserCheck : `${ROUTES.authUserCheck}/${rfqId}`)
      }, 200)
    },
    onError: () => toast.error('Error verifying OTP. Please try again.'),
  });
  const isBusy =
    isVerifying ||
    isSigning ||
    isSignInVerifying ||
    isLogging ||
    isSigningIn ||
    isSigningUp;

  const handleSubmit = (e: FormEvent<HTMLFormElement>, resendOtp = false) => {
    e.preventDefault();
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!emailPattern.test(email)) {
      toast.error('Invalid email address');
      return;
    }
    if (resendOtp) {
      toast.info('OTP has been resent');
    }
    if (mode === 'signup') {
      if (!isOtpSent || resendOtp) signUp({ email });
      else signUpVerifyOTP({ email, OTP });
    } else {
      if (!isOtpSent || resendOtp) signIn({ email });
      else signInVerifyOTP({ email, OTP });
    }
  };

  const handleGoogleLogin = async ({ provider, data }: IResolveParams) => {
    if (data && data.email) {
      const userEmail = data.email;
      if (mode === 'signin') socialSignIn({ email: userEmail });
      if (mode === 'signup') socialSignUp({ email: userEmail });
    }
  };

  const onCaptchaChange = (value: string | null) => {
    setIsCaptchaVerified(!!value);
  };

  const onCaptchaError = () => {
    toast.error('Captcha failed to load. Please try again.');
  };

  useEffect(() => {
    if (mode === 'signup') {
      sessionStorage.clear();
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (mode === 'signin') {
      setTimeout(() => {
        if (userInfo?.user_id && userInfo?.general_profile?.first_name) {
          (!rfqId) ? navigate(ROUTES.authUserCheck) : navigate(`${ROUTES.authUserCheck}/${rfqId}`)
        }
      }, 100)
    }
  }, [rfqId])

  const hadleGetJobData = () => {
    if (rfqDescription) {
      const closingDate = new Date(rfqDescription?.details?.rfq_details?.rfq_closing_date)
      const currentDate = new Date();
      if (closingDate > currentDate) {
        const fetchedData = rfqDescription?.details.rfq_details;
        setJobDetails([
          {
            label: 'Experience',
            value: fetchedData?.experience.length > 0 ? `${fetchedData?.experience[0]?.split(',').join(' to ')} years` : '--',
            icon: '/Assets/Images/loginExp.svg'
          },
          {
            label: 'Locations',
            value: fetchedData?.locations && fetchedData?.locations.length > 0 ? fetchedData?.locations.join(', ') : '--',
            icon: '/Assets/Images/locationBlue.svg'
          },
          {
            label: 'Employee Level',
            value: fetchedData?.employee_level || '--',
            icon: '/Assets/Images/userCheckBlue.svg'
          },
          {
            label: 'Job Type',
            value: fetchedData?.job_type ? fetchedData?.job_type.toString() : '--',
            icon: '/Assets/Images/Job.svg'
          },
          {
            label: 'Visa Type',
            value: fetchedData?.visa_type && fetchedData?.visa_type.length > 0 ? fetchedData?.visa_type.join(', ') : '--',
            icon: '/Assets/Images/visaBlue.svg'
          },
        ]);
      }
    }
  }

  useEffect(() => {
    refetch()
  }, [rfqId])

  useEffect(() => {
    hadleGetJobData()
  }, [rfqDescription])

  return (
    <StyledGrid container>
      <Spinner show={rfqId && isLoading ? true : false}>
       
        <Grid item xs={12} sm={5} md={7}>
          {!rfqId ?
          <Box sx={{width:"100%"}}>
           <LogoImg src={TalentXchangLogo} />
              <BackgroundGridItem src={signUpIllustrtation} alt='Background' />
              </Box>
           :
            <StyledCoverImage>
             
              <img src={signUpIllustrtation} alt="Illustration" />
              <Typography variant='h2' color={themeColors.white}>Talent Xchange</Typography>
              {isExpired ?
                <>
                  <StyledCard>
                    <StyledJobTitle variant='h3'>{rfqDescription?.details?.rfq_details?.job_title}</StyledJobTitle>
                    {jobDetails?.map((item, index) => (
                      <StyledSection>
                        <StyledTypography key={index}>
                          <img src={item?.icon} height={25} width={25} alt={item?.label} /> <StyledSpan>{item?.label}: </StyledSpan><StyledValueSpan>{item?.value}</StyledValueSpan>
                        </StyledTypography>
                      </StyledSection>
                    ))}
                    <StyledSection>
                      <StyledTypography>
                        <ToolsContainer>
                          <img src={'/Assets/Images/primarySkills.svg'} height={25} width={25} alt={'primarySkills'} /> <StyledSpan>Primary Skills: </StyledSpan>
                          {rfqDescription?.details?.rfq_details?.primary_skills?.map((tool, index) => (
                            <ToolBadge key={index}>{tool}</ToolBadge>
                          ))}
                        </ToolsContainer>
                      </StyledTypography>
                    </StyledSection>
                    <StyledCoin>
                      <CoinImageTab>
                        <img src="/Assets/Images/BlueCoin.svg" alt="Coin" />
                        <CoinImageText>
                          {rfqDescription?.details?.rfq_details?.rate_info?.bill_rate || 0}
                        </CoinImageText>
                      </CoinImageTab>
                    </StyledCoin>
                  </StyledCard>
                  <StyledNote><Typography><b><u>Note:</u></b> To access more information about the requisition and make a submission, you must be signed in to the website. </Typography></StyledNote>
                </>
                :
                <StyledCard expiredCard={true}>
                  <img src="/Assets/Images/Expired.png" alt="Expired Job" />
                  <StyledDiv>
                    <Typography variant='h3' color={themeColors.error}>Expired</Typography>
                    <Typography>Oops! This Requisition has been expired. Kindly login to view similar Requisitions.</Typography>
                  </StyledDiv>
                </StyledCard>
              }

            </StyledCoverImage>
          }
        </Grid>
        <Grid item xs={12} md={5} sm={7}>
          <StyledContentBox>
            <LoginForm onSubmit={handleSubmit}>

              <Heading variant='h1'>
                {mode === 'signup' ? 'Sign up' : 'Sign in'}
              </Heading>
              <Box display='flex'>
                <LoginSocialGoogle
                  client_id='768712262599-b37516diro1e1m4s9m48ph7vt47v8obl.apps.googleusercontent.com'
                  onResolve={(params) => handleGoogleLogin(params)}
                  onReject={(error: any) =>
                    toast.error('Something went wrong try again', error)
                  }
                  scope='profile email'
                >
                  <GoogleLoginButton type='button'>
                    {mode === 'signup' ? 'Sign up with google' : 'Sign in with google'}
                  </GoogleLoginButton>
                </LoginSocialGoogle>
              </Box>
              
              {/* <Logos src={or} alt='Or' /> */}/
              <Typography
                variant="body1"
                color='red'
                mt={1}
                mb={1}
                style={{ cursor: 'pointer', color: themeColors.white }}

              >
                (or)
            </Typography>
              <TextField
                fullWidth
                type='email'
                label='Email'
                name='email'
                value={email}
                variant='outlined'
                placeholder='Email Id'
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'white', 
                    },
                    '&:hover fieldset': {
                      borderColor: 'white',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'white',
                    },
                    color: 'white', 
                  },
                  '& .MuiInputLabel-root': {
                    color: 'white', 
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />


              {isOtpSent && (
               <>
                  <MuiOtpInput
                    mt={2}
                    length={4}
                    value={OTP}
                    onChange={(newValue: React.SetStateAction<string>) => setOtp(newValue)}
                    style={{ justifyContent: 'space-between' }}
                    TextFieldsProps={{
                      InputProps: {
                        style: {
                          height: 50,
                          width: 50,
                          color: themeColors.white,
                        },
                      },
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: themeColors.white, 
                          },
                          '&:hover fieldset': {
                            borderColor: themeColors.white,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: themeColors.white, 
                          },
                          '&.Mui-focused': {
                            color: themeColors.white, 
                          },
                        },
                       
                        '& input': {
                          color: themeColors.white,
                          borderColor:themeColors.purpleDark ,
                        },
                      },
                    }}
                  />


               <Typography
                              variant='caption'
                              color='red' 
                              mt={1}
                              style={{ cursor: 'pointer', color:themeColors.white }} 
                              onClick={(e: any) => handleSubmit(e, true)}
               >
                              Resend OTP?
               </Typography>
            </>)}
              {isOtpSent ? (
                <RecaptchaContainer>
                  <ReCAPTCHA
                    sitekey='6Lc_jwEqAAAAAKFTQvT529QjBdzCa0i0kgkNaM2n'
                    onChange={onCaptchaChange}
                    onErrored={onCaptchaError}
                  />
                </RecaptchaContainer>
              ) : (
                ''
              )}
              <SubmitButton
                variant='outlined'
                type='submit'
                disabled={!email || isBusy || (isOtpSent && !isCaptchaVerified)}
              >
                {isBusy ? (
                  <CircularProgress />
                ) : isOtpSent ? (
                  mode === 'signup' ? (
                    'Sign Up'
                  ) : (
                    'Sign In'
                  )
                ) : (
                  'Send OTP'
                )}
              </SubmitButton>
            </LoginForm>
            <ModeText variant='caption'>
              {mode === 'signup' ? (
                <>
                  Already have an account?{' '}
                  <SignInLink href={`${!rfqId ? '/signin' : `/signin/${rfqId}`}`}>Sign in</SignInLink>
                </>
              ) : (
                <>
                  Don't have an account?{' '}
                  <SignInLink href={`${!rfqId ? '/signup' : `/signup/${rfqId}`}`}>Sign Up</SignInLink>
                </>
              )}
            </ModeText>
          </StyledContentBox>
        </Grid>
        <ToastContainer position='bottom-right' autoClose={5000} />
      </Spinner>
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundImage: "url('/Assets/Images/BG Image Sign Up.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
}));


const StyledNote = styled(Paper)({
  background: themeColors.white,
  color: themeColors.error,
  padding: '5px',
  width: 'calc(100% - 150px)'
})

const StyledContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '380px',
  margin: 'auto',
  height: '500px',
  background: `radial-gradient(circle at left bottom, rgba(255, 255, 255, 0.6) -10%, rgba(255, 255, 255, 0) 76%),linear-gradient(180deg, rgba(157, 66, 241, 0.2) 6%, rgba(255, 255, 255, 0) 50.84%)`,
  border: '1px solid rgba(255, 255, 255, 0.4)',
  borderRadius: 22,
  padding: 20,
  position: 'absolute',
  top: '50%',
  transform: 'translate(25%, -60%)',

  
  backdropFilter: 'blur(21px)', 
  WebkitBackdropFilter: 'blur(21px)', 
  boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)', 

  /* Optional: Enhance the glass effect with transparency */
  backgroundClip: 'padding-box', // Ensure the blur doesn't affect the border
});



const BackgroundGridItem = styled.img`
height:"98vh"
`;

const Heading = styled(Typography)({
  fontWeight: 'bold',
  marginBottom: '28px',
  color:themeColors.white,
});

const ModeText = styled(Typography)({
  color: themeColors.white,
});

const Logos = styled.img`
  cursor: pointer;
  padding: 5px;
`;

const SubmitButton = styled(Button)`
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 0;
  width: 100%;
  color: ${themeColors.badgeGray};
  background-color: ${themeColors.otpGrey};
  margin-top: 10px !important;

  &:hover {
    background: linear-gradient(180deg, rgba(157, 66, 241, 0.67) -17.14%, rgba(15, 240, 109, 0.84) 137.14%);
   color: white;
  }

  &:focus {
    background: linear-gradient(180deg, rgba(157, 66, 241, 0.67) -17.14%, rgba(15, 240, 109, 0.84) 137.14%);
    color: white;
  }

  &:active {
    background: linear-gradient(180deg, rgba(157, 66, 241, 0.67) -17.14%, rgba(15, 240, 109, 0.84) 137.14%);
    color: white;
  }

  // Styles when the button is disabled
  &:disabled {
    background-color: grey;
    color: white;
  }

  // When not disabled, apply the gradient
  &:not(:disabled) {
    background: linear-gradient(180deg, rgba(157, 66, 241, 0.67) -17.14%, rgba(15, 240, 109, 0.84) 137.14%);
    color: white;
  }
`;


const LoginForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;
  padding:"20px";
`;


const LogoImg = styled.img`
  display: block;
  height: 72px;
  width: "-webkit-fill-available";
`;

const RecaptchaContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
});

const SignInLink = styled('a')({
  color: themeColors.purpleDark,
  textDecoration: 'underline',
  fontWeight: 'bold',
});

const GoogleLoginButton = styled.button`
  cursor: pointer;
  height:40px;
  width:292px;
  color: ${themeColors.white};
  font-size: 14px;
  font-weight: 500;
  border:1px solid ${themeColors.white};
  border-radius:8px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: 'transparent';
  background-repeat: no-repeat;
  background-position: 12px 11px;
 background-color: transparent;
  &:active {
    background-color: 'transparent';
  }

`;

const ToolBadge = styled(Paper)({
  backgroundColor: themeColors.primaryLight,
  color: themeColors.primaryDark,
  boxShadow: "none",
  padding: "4px 8px",
  borderRadius: "9px",
  fontSize: "12px",
  display: "inline-block",
  margin: '2px',
});

const ToolsContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  margin: "2px 0",
  width: '30vw',
});

const StyledCard = styled(Card)<{ expiredCard?: boolean }>(({ expiredCard }) =>
({
  padding: '30px',
  marginLeft: '50px',
  marginRight: '50px',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: expiredCard ? 'calc(100% - 350px)' : 'calc(100% - 150px)',
  ...(!expiredCard ? {
    // overflow: 'visible',
    overflowY: 'auto',
  } : {
    overflow: 'visible'
  }
  ),
}))

const StyledJobTitle = styled(Typography)({
  paddingBottom: '20px'
})

const StyledSection = styled('section')({
  paddingBottom: '10px'
})

const StyledCoverImage = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '99.9vh',
  flexDirection: 'column',
  gap: '10px',
  background: 'bgImage',
  padding: '100px'
})

const StyledCoin = styled(Box)({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  position: 'relative',
  background: themeColors.primaryLight,
  borderRadius: '8px'
});

const CoinImageTab = styled('div')({
  position: 'relative',
  display: 'inline-block',
  width: '100%',
  height: 'auto',
});

const CoinImageText = styled('div')({
  position: 'absolute',
  color: 'white',
  fontSize: '16px',
  fontWeight: 'bold',
  bottom: '40px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '20%',
  lineHeight: '16px',
  zIndex: 20,
});

const StyledTypography = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledSpan = styled.span`
  margin-left: 6px;
  font-size: 16px;
  font-weight: 450;
  color: ${themeColors.grayBlack}  
`;

const StyledValueSpan = styled('span')({
  fontSize: '16px',
  marginLeft: '5px'
})

const StyledDiv = styled('div')({
  textAlign: 'center'
})

export default SignUp;
