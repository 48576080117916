import React, { ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Loader from'../../assets/images/loader.gif'

interface SpinnerProps {
    show: boolean;
    children?: ReactNode;
}

const Spinner: React.FC<SpinnerProps> = ({ show, children }) => {
    const [reset, setReset] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setReset((prevReset) => !prevReset);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {show ? (
                <Backdrop>
                    <LoaderContainer>
                        <ImageLoader reset={reset} src={Loader} alt="Loading..." />
                        <ProgressBarLabel>
                            <ProgressBarFill reset={reset} />
                        </ProgressBarLabel>
                    </LoaderContainer>
                </Backdrop>
            ) : (
                <>{children}</>
            )}
        </>
    );
};


const Backdrop = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999,
});

const LoaderContainer = styled(Box)({
    position: 'relative',
    width: '200px',
    height: '80px',
});

const ImageLoader = styled('img')<{ reset: boolean }>(({ reset }) => ({
    position: 'absolute',
    top: '10px',
    zIndex: 1,
    height: '50px',
    animation: reset ? 'none' : 'move 5s linear infinite',
    '@keyframes move': {
        '0%': {
            left: '-50%',
            opacity: 0,
        },
        '10%': {
            opacity: 1,
        },
        '90%': {
            opacity: 1,
        },
        '100%': {
            left: '100%',
            opacity: 0,
        },
    },
}));

const ProgressBarLabel = styled(Box)({
    position: 'absolute',
    width: '200px',
    height: '8px',
    top: '70px',
    borderRadius: '5px',
    backgroundColor: '#e0e0e0',
    overflow: 'hidden',
});

const ProgressBarFill = styled(Box)<{ reset: boolean }>(({ reset }) => ({
    height: '100%',
    background: 'linear-gradient(90deg, #00c15d 0%, #9d42f1 12.5%, #7a33bb 25%, #1749c7 37.5%, #4d6cd9 50%, #38f4a4 62.5%, #00e777 75%, #b580f4 87.5%)',
    backgroundSize: '200px 8px',
    borderRadius: '5px',
    animation: reset ? 'none' : 'fill 5s linear infinite',
    '@keyframes fill': {
        '0%': {
            width: '0%',
        },
        '100%': {
            width: '100%',
        },
    },
}));

export default Spinner;
