import { api } from "api";
import { AxiosError, AxiosResponse } from "axios";
import {
  SignupModal,
  VerifyOTPModal,
  PostUserIDdetailsModal,
  User,
  PostCompanyDataModal,
  RolesDataModal,
  RFRDraftModal,
  CreateRFRModal,
  QuickRFRformModal,
  LocationModal,
  RFRDetailsModal,
  VendorModal,
  EditRFRModal,
  ChangeDateRequisitionDataModal,
  RecentSubmissionModal,
  EmpDashboardJourneyModal,
  RequisitionAboutToExpireModal,
  RFQDescription,
  CreateRfqVendorSubmission,
  VendorRaisedSubmissions,
  AnalysisModal,
  CreateCV,
  MoveRequesitionmodel,
  VendorMySubmissionProps,
  CvDetailsModal,
  VenderAllRequisitionModel,
  ActiveRequisitionsModal,
  AcceptOrRejectSubmissionModal,
  RequestForSubmissionModal,
  Widgets,
  SelectedWidgetOptions,
  SubmissionDetailsModal,
  SubmissionCvModal,
  SubmissionDetailCvList,
  VendorSubmitMultipleCvs,
  EditOrMoveIntOrNotInterestedReqResponceModel,
  VendorEditCvProps,
  VendorCvEditGetData,
  VendorJobSubmission,
  CvContactDetailsModal,
  VendorMysubmitionsEmployer,
  CvNotesDetails,
  VendorYourJournetSoFar,
  VendorDashBoardOpenRequisitionsProps,
  SubmissionDetailsCvList,
  VendorDashboardSubmissionsProps,
  VendorCreateQuickSubmissionProps,
  NotifyAgainModal,
  CvEmpContactDetailsModal,
} from "./modals";

export const signUp = (data: SignupModal) =>
  api
    .post("/c2c_user_signUp_sendOTP", data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => Promise.reject(error));
export const signUpVerifyOTP = (data: VerifyOTPModal): Promise<User> => {
  return api
    .post("/c2c_user_signUp_validateOTP", data)
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
};
export const signIn = async (data: SignupModal): Promise<User> => {
  try {
    const response = await api.post("/c2c_user_signIn_sendOTP", data);
    return response.data;
  } catch (error) {
    return await Promise.reject(error);
  }
};
export const signInVerifyOTP = async (data: VerifyOTPModal): Promise<User> => {
  try {
    const response = await api.post("/c2c_user_signIn_validateOTP", data);
    return response.data;
  } catch (error) {
    return await Promise.reject(error);
  }
};
export const socialSignIN = async (data: SignupModal): Promise<User> => {
  try {
    const response = await api.post("/c2c_user_social_signIn", data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const socialSignUp = async (data: SignupModal): Promise<User> => {
  try {
    const response = await api.post("/c2c_user_social_signUp", data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getUserIDDetails = async (
  userId: string | null
): Promise<User> => {
  try {
    const response = await api.get("/C2C_UserByID", {
      params: {
        userID: userId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRFRDetails = async (
  rfqId?: string
): Promise<RFRDetailsModal> => {
  try {
    const response = await api.get("/Get_job_description", {
      params: {
        Rfq_ID: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRfqNotInterested = async (
  rfqId?: string
): Promise<VendorModal[]> => {
  try {
    const response = await api.get("/employerNotInterestedVendors", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRfqInterested = async (
  rfqId?: string
): Promise<VendorModal[]> => {
  try {
    const response = await api.get("/employerInterestedVendors", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRfqAccepted = async (
  rfqId?: string
): Promise<VendorModal[]> => {
  try {
    const response = await api.get("/EMP_RFQ_ACCEPTED_BIDS", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRfqRequestedSubmissions = async (
  rfqId?: string
): Promise<VendorModal[]> => {
  try {
    const response = await api.get("/employerRequisitionRequestedBids", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSubmissionDetails = async (
  SubmissionId?: string
): Promise<SubmissionDetailsModal[]> => {
  try {
    const response = await api.get("/c2cEmployerSubmmissionTable", {
      params: {
        submmissionID: SubmissionId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRfqRejected = async (
  rfqId?: string
): Promise<VendorModal[]> => {
  try {
    const response = await api.get("/EMP_RFQ_REJECTED_BIDS", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRfqRequestedCVs = async (
  rfqId?: string
): Promise<VendorModal[]> => {
  try {
    const response = await api.get("/EMP_RFQ_REQUEST_CVS", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRfqPending = async (rfqId?: string): Promise<VendorModal[]> => {
  try {
    const response = await api.get("/EMP_RFQ_PENDING_BIDS", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRfqAnalysis = async (
  rfqId?: string
): Promise<AnalysisModal[]> => {
  try {
    const response = await api.get("/employerRequisitionAnalytics", {
      params: {
        rfqId: rfqId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postUserIDDetails = async (
  data: PostUserIDdetailsModal
): Promise<User> => {
  try {
    const response = await api.post("/postSignUp_general_Information", data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postJDDetails = async (formData: FormData): Promise<any> => {
  try {
    const response = await api.post(
      "/Emp_rfr_detailsPage_Change_JD",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUserLocation = async (): Promise<LocationModal[]> => {
  try {
    const response = await api.get("/RFQ_location");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const postAboutCompanyData = async (
  data: PostCompanyDataModal
): Promise<User> => {
  try {
    const response = await api.post("/postSignUpForm_aboutCompany", data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRoles = async (): Promise<{ role: string }[]> => {
  try {
    const response = await api.get("/getRoles");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const postRolesData = async (data: RolesDataModal): Promise<User> => {
  try {
    const response = await api.post(
      "/postSignUpForm_role_specifications",
      data
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRfrListAll = async (userId: string): Promise<User> => {
  try {
    const response = await api.get("/rfr_listing_All", {
      params: {
        user_id: userId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRFRVisaType = async (): Promise<{ Visa: string }[]> => {
  try {
    const response = await api.get("/RFQ_VisaType");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRFQlocations = async (): Promise<
  {
    state: string;
  }[]
> => {
  try {
    const response = await api.get("/RFQ_location");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRFQsecodaryskills = async (): Promise<
  {
    skill: string;
  }[]
> => {
  try {
    const response = await api.get("/RFQ_primary/secondary_skills");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRfqpositon = async (): Promise<{ positions: string }[]> => {
  try {
    const response = await api.get("/RFQ_positions");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRFRJobCategory = async (): Promise<
  { jobCategory: string }[]
> => {
  try {
    const response = await api.get("/RFQ_job_category");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRFqJobType = async (): Promise<
  {
    contractType: string;
  }[]
> => {
  try {
    const response = await api.get("/RFQ_job_type");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getRFqEmployeeLevel = async (): Promise<
  {
    level: string;
  }[]
> => {
  try {
    const response = await api.get("/rfr_emp_Level");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const postCreateRFRData = async (
  data: CreateRFRModal
): Promise<User> => {
  try {
    const response = await api.post("/create_RFR_v1", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const postDraftRFRData = async (data: RFRDraftModal): Promise<User> => {
  try {
    const response = await api.post("/create_RFR_Draft", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postAcceptOrRejectSubmissionData = async (
  data: AcceptOrRejectSubmissionModal
): Promise<User> => {
  try {
    const response = await api.post(
      "/empRequisitionAcceptedRejectedAction",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getQuickRfqSkills = async (): Promise<{ skill: string }[]> => {
  try {
    const response = await api.get("/RFQ_primary/secondary_skills");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getQuickRfqVisatype = async (): Promise<{ Visa: string }[]> => {
  try {
    const response = await api.get("/RFQ_VisaType");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createQuickRfrDetails = async (
  data: QuickRFRformModal
): Promise<User> => {
  try {
    const response = await api.post("/create_quick_RFR", data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postDeleteRequisition = async (rfqId: string): Promise<User> => {
  try {
    const response = await api.delete(
      `/Emp_RFQ_listing_page_delete_requistion`,
      {
        data: { rfqId },
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postChangeDateRequisition = async (
  data: ChangeDateRequisitionDataModal
): Promise<User> => {
  try {
    const response = await api.post("/Emp_RFQ_listing_page_change_date", data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRFReditdetails = async (
  rfrId: string | null
): Promise<void> => {
  try {
    const response = await api.get("/Emp_RFQ_listing_page_preview_requistion", {
      params: {
        rfrId: rfrId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postEditRfrDetails = async (data: EditRFRModal): Promise<User> => {
  try {
    const response = await api.post(
      "/Emp_RFQ_listing_page_edit_requistion",
      data
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRecentsubmissions = async (userID: string): Promise<
  RecentSubmissionModal[]
> => {
  try {
    const response = await api.get(`/Emp_Dashboard_recent_bids`,{
      params: { userID },
    });

    return response.data;
  } catch (error) {
    return Promise.reject(error); 
  }
};

export const getJourney = async (userID: string): Promise<EmpDashboardJourneyModal[]> => {
  try {
    const response = await api.get(`/Emp_dash_journey`,{
      params: { userID },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRFQDescription = async (
  rfqId: string
): Promise<RFQDescription> => {
  try {
    const response = await api.get(`/Vendor_RFQ_Description`, {
      params: { rfqId },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postCreateVendorSubmission = async (
  data: CreateRfqVendorSubmission
): Promise<User> => {
  try {
    const response = await api.post("/C2C_create_submission_v2", data, {
      headers: { "Content-Type": "application/json" },
      timeout: 100000,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRaisedSubmissions = async (
  rfqId: string,
  userId: string
): Promise<VendorRaisedSubmissions[]> => {
  try {
    const response = await api.get("/C2C_RAISED_SUBMISSIONS", {
      params: { rfq_id: rfqId, userID: userId },
      headers: {
        accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAboutToExpireyData = async (userID: string): Promise<
  RequisitionAboutToExpireModal[]
> => {
  try {
    const response = await api.get(`/Emp_dash_expire_rfq`,{
      params: { userID },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postRestoreRequisition = async (rfqId: string): Promise<User> => {
  try {
    const response = await api.post(
      `/Emp_RFQ_listing_page_restore_requistion`,
      {
        rfqId,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRmpDataAll = async (
  vendorId: string
): Promise<VenderAllRequisitionModel[]> => {
  try {
    const response = await api.get(`/VENDOR_MARKPLACE_LIST_RFQS`, {
      params: {
        vendorID: vendorId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRmpIntrestedData = async (
  vendorId: string
): Promise<VenderAllRequisitionModel[]> => {
  try {
    const response = await api.get(`/VENDOR_INTERESTED_BIDS`, {
      params: {
        vendorID: vendorId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRmpNotIntrestedData = async (
  vendorId: string
): Promise<VenderAllRequisitionModel[]> => {
  try {
    const response = await api.get(`/VENDOR_NOT_INTERESTED_BIDS`, {
      params: {
        vendorID: vendorId,
      },
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllSubmissionsList = async ( empId: string): Promise<User> => {
  try {
    const response = await api.get("/empAllSubmissions",{
      params:{
        empId:empId,
      }
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRFRShare = async (rfqID: string | null): Promise<void> => {
  try {
    const response = await api.get("/copyRFQ", {
      params: {
        rfqID: rfqID,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const moveRequesitionSingle = async (
  data: MoveRequesitionmodel
): Promise<EditOrMoveIntOrNotInterestedReqResponceModel> => {
  try {
    const response = await api.post(`/Vendor_interested/notIntrested`, data);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editRequesitionSingle = async (
  data: MoveRequesitionmodel
): Promise<EditOrMoveIntOrNotInterestedReqResponceModel> => {
  try {
    const response = await api.post(
      `/c2cVendorIntNotIntEditSingleRecords`,
      data
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const CreateUserCV = async (data: CreateCV): Promise<User> => {
  try {
    const response = await api.post("/Create_CV", data, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const GetVendorMySubmissions = async (
  userId?: string
): Promise<VendorMySubmissionProps[]> => {
  try {
    const response = await api.get("/c2cVendorRaisedSubmissions", {
      params: {
        userID: userId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const GetVendorEmployerRequests  = async (
  userId?: string
): Promise<VendorMysubmitionsEmployer[]> => {
  try {
    const response = await api.get("/c2cVendorEmployerRequests", {
      params: {
        userID: userId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getActiveRequisition =
  async (userID: string): Promise<ActiveRequisitionsModal> => {
    try {
      const response = await api.get(`/Emp_dash_active_rfq`,{
        params: { userID },
      });
  
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const postRequestForSubmissionData = async (
  data: RequestForSubmissionModal
): Promise<User> => {
  try {
    const response = await api.post("/rasieRequestForSubmission", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const postVendorWidgets = async (data: Widgets): Promise<User> => {
  try {
    const response = await api.post("/widgetPersistanceVendor", data, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRFQqualification = async (): Promise<
  {
    qualificationLevel: string;
  }[]
> => {
  try {
    const response = await api.get("/RFQ_qualification");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postEmployerWidgets = async (data: Widgets): Promise<User> => {
  try {
    const response = await api.post("/widgetPersistanceEmployer", data, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVendorWidgets = async (
  userId: string
): Promise<SelectedWidgetOptions> => {
  try {
    const response = await api.get("/persistanceVendor", {
      params: {
        user_id: userId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEmployerWidgets = async (
  userId: string
): Promise<SelectedWidgetOptions> => {
  try {
    const response = await api.get("/persistanceEmployer", {
      params: {
        user_id: userId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const VendorAddMultipleCv = async (
  data: VendorSubmitMultipleCvs
): Promise<User> => {
  try {
    const response = await api.post("/submissionAddMultipleCVs", data, {
      headers: { "Content-Type": "application/json" },
      timeout: 100000
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const GetVendorSubmissionCvs = async (
  submissionId: string | number | null
): Promise<SubmissionDetailCvList> => {
  try {
    const response = await api.get("/getSubmissionCVs", {
      params: {
        sub_id: submissionId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSubmissionsDetails = async (
  submissionID?: string
): Promise<SubmissionDetailsModal> => {
  try {
    const response = await api.get("/employerSubmissionDetails", {
      params: {
        submissionID: submissionID,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCvDetails = async (
  submmissionID?: string
): Promise<SubmissionCvModal> => {
  try {
    const response = await api.get("/c2cEmployerSubmmissionTable", {
      params: {
        submmissionID: submmissionID,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const VendorEditCv = async (data: VendorEditCvProps): Promise<User> => {
  try {
    const response = await api.post("/c2cVendorChangeCv", data, {
      headers: { "Content-Type": "application/json" },
      timeout: 100000,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const postCvLikeOrDislike = async (cvID: string,status:string): Promise<User> => {
  try {
    const response = await api.post(
      `/c2cVendorCvsLikeDislike`,
      {
        cvID,status,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getVendorSubmissionDetails = async (submissionId: string): Promise<VendorJobSubmission> => {
  try {
    const response = await api.get(`/vendorSubmissionDetails`, {
      params: { submissionId },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCvContactDetails = async (cvId: string,vendorId:string): Promise<CvContactDetailsModal> => {
  try {
    const response = await api.get(`/c2cViewContactDetails`, {
      params: { cvId,vendorId },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postCvNotes = async (cvId: string,notes:string): Promise<User> => {
  try {
    const response = await api.post(
      `/c2cCvNotes`,
      {
        cvId,notes,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export const getCvNotes = async (cvId: string): Promise<CvNotesDetails> => {
  try {
    const response = await api.get(`/c2cCvNotesHistory`, {
      params: { cvId },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVendorYourJourney = async (vendorId: string): Promise<VendorYourJournetSoFar> => {
  try {
    const response = await api.get(`/c2cVendorDashboardJourney`, {
      params: { vendorId },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVendorDashboardRequesitions = async (vendorID: string): Promise<VendorDashBoardOpenRequisitionsProps[]> => {
  try {
    const response = await api.get(`/c2cVendorDashBoardOpenRequisitions`, {
      params: { vendorID },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error)
  }
}

export const GetVendorsSubmissionCvs = async (
  submissionId: string | number | null
): Promise<SubmissionDetailsCvList[]> => {
  try {
    const response = await api.get("/getSubmissionCVsVendor", {
      params: {
        sub_id: submissionId,
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};


export const getVendorDashboardSubmissions = async (userID: string): Promise<VendorDashboardSubmissionsProps[]> => {
  try {
    const response = await api.get(`/c2cVendorDashBoardMySubmissions`, {
      params: { userID },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error)
  }
}

export const getVendorCreateQuickSubmission = async (vendorID: string): Promise<VendorCreateQuickSubmissionProps[]> => {
  try {
    const response = await api.get(`/c2cVendorCreateQuickSubmission`, {
      params: { vendorID },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error)
  }
};

export const postPublishRequisition = async (rfqId: string): Promise<User> => {
  try {
    const response = await api.post(
      `/c2cEmployerPublishRequisition`,
      {
         rfqId ,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postNotifyVendor = async (
  data: NotifyAgainModal
): Promise<User> => {
  try {
    const response = await api.post("/c2c_emp_notify_again_POST", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getEnpCvContactDetails = async (submissionId: string,vendorId:string): Promise<CvEmpContactDetailsModal> => {
  try {
    const response = await api.get(`/c2cEmployerViewContactDetails`, {
      params: { submissionId,vendorId },
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const postCvsOnHold = async (cvID: string,status:string): Promise<User> => {
  try {
    const response = await api.post(
      `/c2cVendorCvsOnHold`,
      {
        cvID,status,
      }
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
