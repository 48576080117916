import styled from "@emotion/styled";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material"
import PageHeader from "components/pageHeaders/PageHeader";
import { SharedTabPannel } from "components/SharedTabPannel";
import { themeColors } from "core/Theming/theme";
import { useState } from "react";
import CreateCvs from "./CreateCvs/CreateCvs";
import { useAppSelector } from "core/store";
import { useNavigate } from "react-router-dom";

const MyCvs = () => {
    const navigate = useNavigate()
    const userId = useAppSelector((state) => state.auth?.userInfo?.user_id);
    const [tabValue, setTabValue] = useState(0)

    function a11yProps(index: number) {
        return {
            id: `tab-${index}`,
            "aria-controls": `tab-panel-${index}`,
        };
    }

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    return (
        <MainContainer>
            <PageHeader heading="My CVs" backgroundColor={themeColors.purpleLight || ''} backButton={false} />

            {/* <StyledTabContainer>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="CV Database" {...a11yProps(0)} />
                        <Tab label="Matchmaking" {...a11yProps(1)} />
                        <Tab label="Add New CV" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <SharedTabPannel value={tabValue} index={0}>
                    <h1>Hello CV Database</h1>
                </SharedTabPannel>
                <SharedTabPannel value={tabValue} index={1}>
                    <h1>Hello Matchmaking</h1>
                </SharedTabPannel>
                <SharedTabPannel value={tabValue} index={2}>
                    <CreateCvs userId={userId} />
                </SharedTabPannel>
            </StyledTabContainer> */}
            <Container>
                <img height={350} src="/Assets/comingSoonImages/myCvs.png" alt="" />
                <Styletextcontainer>
                    <Styletext variant="h4">Coming Soon...</Styletext>
                </Styletextcontainer>
            </Container>
            <Footer>
                <StyledButton
                    variant="contained"
                    onClick={() => navigate("/vendor/dashboard")}
                >
                    Go back to Dashboard
                </StyledButton>
            </Footer>
        </MainContainer>
    )
}

const MainContainer = styled(Box)`
width: 100%;,
height:100%,
background-color: ${themeColors.white};
padding-top: 25px;
padding-left: 30px;
padding-right: 30px;
`;

const Footer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "16px",
});

const StyledTabContainer = styled(Box)({
    marginTop: '12px',
    paddingTop: '15px'
})

const StyledComingSoon = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px'
})


const Styletext = styled(Typography)({
    color: themeColors.blackGray,
  });

const StyledButton = styled(Button)({
    borderRadius: "10px",
    width: "350px",
    height: "44px",
    fontSize: "12px",
});

const Styletextcontainer = styled(Box)({
    position: "absolute",
    width: "351px",
    height: "56px",
    backgroundColor: themeColors.white,
    color: themeColors.blackGray,
  });

const Container = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
});

export default MyCvs