import React from 'react'
import { Box, Typography, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Imag2 from '../../assets/images/LandingPage/Vector2.svg';
import Imag1 from '../../assets/images/LandingPage/Vector.svg';
import CardActionArea from '@mui/material/CardActionArea';
import Imag3 from '../../assets/images/LandingPage/Vector3.svg';
import Imag6 from '../../assets/images/LandingPage/undraw_security_on_re_e491 1.svg';
import Imag5 from '../../assets/images/LandingPage/undraw_task_list_6x9d 1.svg';
import Imag4 from '../../assets/images/LandingPage/undraw_video_call_re_4p26 1.svg';
import styled from '@emotion/styled';

const VAS: React.FC = () => {
    return (
        <div className='page2background'>
            <Box sx={{ flexGrow: 1 }}>
                <Typography
                    variant="h1"
                    component="h2"
                    className="mainp3"
                    sx={{marginTop:'50px'}}
                >
                    Value added Services
                </Typography>
                <div className='textvas '>


                    Gain Access to Multiple 3rd-Party Service Providers on a Single Platform, Enhancing the Accuracy and Efficiency of Your Requisitions
                </div>
                <p className='comngsoon'>Coming soon..</p>

                <Grid className='cardgridvas'>
                    {/* Card1  */}
                    <Card sx={{ maxWidth: 300, marginBottom: '25px' }}>
                        <CardActionArea className="cardcomponent">

                            <CardMedia
                                component="img"
                                image={Imag4}
                                alt="Imag4"
                                sx={{
                                    background: 'var(--Main-Gradient, linear-gradient(113deg, rgba(255, 255, 255, 0.47) 0%, rgba(255, 255, 255, 0.00) 110.84%))',
                                    backdropFilter: 'blur(5)',
                                    margin: '0 auto',
                                    objectFit: 'contain',
                                    width: '200px',
                                    height: '200px'
                                }}
                            />


                            <CardContent className='cardfooter1'>
                                <Typographystyle gutterBottom variant="h5">
                                    Interview-As-Service
                                </Typographystyle>
                                <Typographystyle variant="body2" sx={{ color: 'text.secondary' }}>
                                    offering broad access to 20+ interview services for a more efficient and effective hiring journey
                                </Typographystyle>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    {/* Card2  */}
                    <Card sx={{ maxWidth: 300, marginBottom: '25px' }}>
                        <CardActionArea>

                            <CardContent className='cardfooter1'>
                                <Typographystyle gutterBottom variant="h5">
                                    Assessment-As-service
                                </Typographystyle>
                                <Typographystyle variant="body2" sx={{ color: 'text.secondary' }}>
                                    Access to a variety of assessment tools, streamlining the evaluation process
                                </Typographystyle>
                            </CardContent>
                            <CardMedia
                                component="img"
                                image={Imag5}
                                alt="Imag5"
                                sx={{
                                    background: 'linear-gradient(113deg, rgba(255, 255, 255, 0.47) 0%, rgba(255, 255, 255, 0.00) 110.84%))',
                                    backdropFilter: 'blur(21px)',
                                    margin: '0 auto',
                                    objectFit: 'contain',
                                    width: '200px',
                                    height: '200px'
                                }}
                            />

                        </CardActionArea>
                    </Card>
                    {/* Card3  */}
                    <Card sx={{ maxWidth: 300, marginBottom: '27px' }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                image={Imag6}
                                alt="Imag6"
                                sx={{
                                    backgroundColor: 'linear-gradient(113deg, rgba(255, 255, 255, 0.47) 0%, rgba(255, 255, 255, 0.00) 110.84%)',
                                    backdropFilter: 'blur(21px)',
                                    margin: '0 auto',
                                    objectFit: 'contain',
                                    width: '200px',
                                    height: '200px'
                                }}
                            />


                            <CardContent className='cardfooter1'>
                                <Typographystyle gutterBottom variant="h5">
                                    Background Verification Check-As- Service
                                </Typographystyle>
                                <Typographystyle variant="body2" sx={{ color: 'text.secondary' }}>
                                    Integrated with 3rd-Party Services for Comprehensive Candidate Screening
                                </Typographystyle>
                            </CardContent>
                        </CardActionArea>
                    </Card>

                </Grid>
                <Typography
                    variant="h1"
                    component="h2"
                    className="testimonyvas"
                >
                    Testimonials
                </Typography>
                <Grid className='cardgridvas'>
                    {/* Card1  */}
                    <Card sx={{ maxWidth: 350, marginBottom: '25px' }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                image={Imag1}
                                alt="Imag1"
                                height="200"
                            />

                            <CardContent className='cardfooter'>
                                <Typographystyle gutterBottom variant="h5" >
                                    Isabella Chavez
                                </Typographystyle>
                                <Typographystyle variant="body2" sx={{ color: 'text.secondary' }}>
                                    “You made it so simple. My new site is so much faster and easier to work with than my old site.”
                                </Typographystyle>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    {/* Card2  */}
                    <Card sx={{ maxWidth: 350, marginBottom: '25px' }}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                image={Imag2}
                                alt="Imag2"
                                height="200"

                            />
                            <CardContent className='cardfooter'>
                                <Typographystyle gutterBottom variant="h5" >
                                    Isabella Chavez
                                </Typographystyle>
                                <Typographystyle variant="body2" sx={{ color: 'text.secondary' }}>
                                    “You made it so simple. My new site is so much faster and easier to work with than my old site.”
                                </Typographystyle>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    {/* Card3  */}
                    <Card sx={{ maxWidth: 350, marginBottom: '25px' }}>
                        <CardActionArea>
                            <Box >
                                <CardMedia
                                    component="img"
                                    image={Imag3}
                                    alt="Imag3"
                                    height="200"
                                />
                            </Box>
                            <CardContent className='cardfooter'>
                                <Typographystyle gutterBottom variant="h4" >
                                    Isabella Chavez
                                </Typographystyle>
                                <Typographystyle variant="body2" sx={{ color: 'text.secondary' }}>
                                    “You made it so simple. My new site is so much f4aster and easier to work with than my old site.”
                                </Typographystyle>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Box>
        </div>
    )
}
export default VAS;
const CardContentStyle = styled(CardContent)`
color:#fff;
`
const Typographystyle = styled(Typography)`
color:#fff;
text-align:center;
`