import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Button, Menu, MenuItem, MenuProps, Typography } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { themeColors } from 'core/Theming/theme';
import { useAppSelector } from 'core/store';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

interface PaginationProps {
    itemsPerPage: number;
    totalItems: number;
    currentPage: number;
    initialItemsPerPage: number;
    onPageChange: (page: number) => void;
    onItemPerChange: (itemPerPageSet: number) => void
}

const PaginationComponent: React.FC<PaginationProps> = ({ itemsPerPage, initialItemsPerPage, totalItems, currentPage, onPageChange, onItemPerChange }) => {
    const [totalItemsperPage, setTotalItemsperPage] = useState(itemsPerPage);
    const [totalPages, setTotalPages] = useState(Math.ceil(totalItems / totalItemsperPage));
    const isVendor = useAppSelector(store => store.auth.isVendor);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (totalCount?: number) => {
        if (totalCount) {
            setTotalItemsperPage(totalCount);
            onItemPerChange(totalCount); 
        }
        setAnchorEl(null);
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / totalItemsperPage));
    }, [totalItems, totalItemsperPage]);

    useEffect(() => {
        if (currentPage > totalPages) {
            onPageChange(totalPages);
        }
    }, [totalPages, currentPage]);

    if (totalItems === 0) return null;

    return (
        <StyledPaginationComponent isVendor={isVendor}>
            <Typography>
                Showing
                <Button
                    sx={{ margin: '0 8px' }}
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    {totalItemsperPage}
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose()}
                >
                    <MenuItem onClick={() => handleClose(initialItemsPerPage)} disableRipple>
                        {initialItemsPerPage}
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(initialItemsPerPage + 5)} disableRipple>
                        {initialItemsPerPage + 5}
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(initialItemsPerPage + 10)} disableRipple>
                        {initialItemsPerPage + 10}
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(initialItemsPerPage + 20)} disableRipple>
                        {initialItemsPerPage + 20}
                    </MenuItem>
                </StyledMenu>
                results per page
            </Typography>
            <ButtonComponent>
                <StyledButton variant='outlined' onClick={handlePrevClick} disabled={currentPage === 1}>
                    <StyledSpan>
                        <KeyboardArrowLeftOutlinedIcon />back
                    </StyledSpan>
                </StyledButton>
                <span>{`showing ${currentPage} of ${totalPages} page${totalPages > 1 ? 's' : ''}`}</span>
                <StyledButton variant='outlined' onClick={handleNextClick} disabled={currentPage === totalPages}>
                    <StyledSpan>
                        next<ChevronRightOutlinedIcon />
                    </StyledSpan>
                </StyledButton>
            </ButtonComponent>
        </StyledPaginationComponent>
    );
};


const StyledPaginationComponent = styled('div')<{ isVendor: boolean | null }>(({ isVendor }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: isVendor ? themeColors.purpleLight : themeColors.primaryLight
}));

const ButtonComponent = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
    padding: '2px',
})

const StyledButton = styled(Button)({
    cursor: 'pointer'
});

const StyledSpan = styled('span')({
    display: 'flex',
    alignItems: 'center',
    gap: 2,
});

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
        },
    },
}));

export default PaginationComponent;
