import styled from "@emotion/styled";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { AccordionSummary, Box, Card, Grid, Paper, Switch, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { SubmissionDetailCvList, VendorJobSubmission } from "api/resources/modals";
import PageHeader from "components/pageHeaders/PageHeader";
import { SharedTabPannel } from "components/SharedTabPannel";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import { useGetVendorSubmissionCvs, useVendorSubmissionDetails } from "pages/hooks";
import SubmissionDetails from "pages/Vendor/rmp/rmpDetails/submissionDetails/SubmissionDetails";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import VendorSubmissionCardView from "./cvCardView/VendorSubmissionCardView";
import CvDetailsComponent from "./cvDetailsCard/CvDetailsCard";

const VendorSubmissionDetails = () => {
    const [tabValue, setTabValue] = useState(0);
    const [tableView, setTableView] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [details, setDetails] = useState<{ label: string, value: string | number | null, icon: string }[]>([]);
    const [allCvs, setAllCvs] = useState<SubmissionDetailCvList['allCvs']>([]);
    const [likedCvs, setLikedCvs] = useState<SubmissionDetailCvList['allCvs']>([]);
    const [unlikedCvs, setUnlikedCvs] = useState<SubmissionDetailCvList['allCvs']>([]);
    const [onHoldCvs, setOnHoldCvs] = useState<SubmissionDetailCvList['allCvs']>([])
    const [vendorDetails, setVendorDetails] = useState<VendorJobSubmission | null>(null);
    const [spinner, showSpinner] = useState(false)

    const { id: submissionId } = useParams<{ id: string }>();

    const { vendorMySubmissionDetails, refetch: fetchVendorDetails, isLoading } = useVendorSubmissionDetails(submissionId || '');
    const { submissonCvList, refetch: fetchCvs, isLoading: CvFetchning } = useGetVendorSubmissionCvs(submissionId || '');

    const fetchData = useMemo(async () => {
        try {
            const vendorResponse = await fetchVendorDetails();
            setVendorDetails(vendorResponse.data || null);

            const cvResponse = await fetchCvs();
            if (cvResponse?.data) {
                setAllCvs(cvResponse.data?.allCvs);
            } else {
                setAllCvs([]);
            }
        } catch (error) {
            toast.error('something went wrong please try again later')
        }
    }, [fetchCvs, fetchVendorDetails, tabValue]);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleCallCvApi = async (updatedData: boolean) => {
        if (updatedData) {
            const cvResponse = await fetchCvs()
            if (cvResponse?.data) {
                setAllCvs(cvResponse.data?.allCvs || []);
            } else {
                setAllCvs([]);
            }
        }
    }

    useEffect(() => {
        if (vendorDetails) {
            setDetails([
                {
                    label: 'Visa Type / Work Permit',
                    value: vendorDetails?.visaTypeWorkPermit?.join(', ') || '--',
                    icon: '/Assets/Images/visa.svg',
                },
                {
                    label: 'Employee Level',
                    value: vendorDetails?.employerLevel || '--',
                    icon: '/Assets/Images/userCheck.svg',
                },
                {
                    label: 'Submission sent to (requestion name with ID)',
                    value: vendorDetails?.submissionSentTo?.toString() || '--',
                    icon: '/Assets/Images/userPlus.svg',
                },
                {
                    label: 'Location',
                    value: vendorDetails?.location?.join(', ') || '--',
                    icon: '/Assets/Images/location.svg',
                },
                {
                    label: 'Commission',
                    value: vendorDetails?.comission || '--',
                    icon: '/Assets/Images/commission.svg',
                },
            ]);
        }
    }, [vendorDetails]);

    useEffect(() => {
        showSpinner(true)
        if (tabValue === 0) {
            setAllCvs(submissonCvList?.allCvs || []);
            showSpinner(false)
        } else if (tabValue === 1) {
            setLikedCvs(submissonCvList?.allCvs?.filter(cv => cv.status === 'Liked') || []);
            showSpinner(false);
        } else if (tabValue === 2) {
            setUnlikedCvs(submissonCvList?.allCvs?.filter(cv => cv.status === 'Disliked') || []);
            showSpinner(false);
        } else if (tabValue === 3) {
            setOnHoldCvs(submissonCvList?.allCvs?.filter(cv => cv.status === 'On hold') || []);
        }
    }, [tabValue, submissonCvList, fetchData, fetchCvs]);

    useEffect(() => {
        if (submissionId) {
            fetchData;
        }
    }, [submissionId, fetchData, tabValue]);

    return (
        <MainContainer>
            <PageHeader backButton={true} navigationUrl={'/vendor/my-submissions'} backgroundColor={themeColors.white || ''} heading='All Submissions' icon="/Assets/Images/paperPurple.svg" />
            <Spinner show={isLoading || CvFetchning}>
                <StyledCard>
                    <StyledHeader>
                        <StyledBox>
                            <StyledTypography variant="h3">
                                {vendorDetails?.submissionSentTo}
                            </StyledTypography>
                        </StyledBox>
                        <StyledAccordionSummary
                            expandIcon={expanded ? <StyledKeyboardArrowUpOutlinedIcon /> : <StyledGridExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={() => setExpanded(!expanded)}
                        />
                    </StyledHeader>
                    {expanded && (
                        <>
                            <SubContainer>
                                <StyledGridItem item md={6}>
                                    {details.slice(0, 3).map((detail, index) => (
                                        <CvDetailsComponent key={index} jobDetails={detail} />
                                    ))}
                                </StyledGridItem>
                                <StyledGridItem item md={6}>
                                    {details.slice(3).map((detail, index) => (
                                        <CvDetailsComponent key={index} jobDetails={detail} />
                                    ))}
                                </StyledGridItem>
                                <StyledGridItem>
                                    <Typography variant="h4">
                                        Rate Info
                                    </Typography>
                                    <StyledCoin>
                                        <CoinImageTab>
                                            <img src={`/Assets/Images/${vendorDetails?.rateInfo?.includes('hour') ? 'Hour.svg' : vendorDetails?.rateInfo?.includes('week') ? 'Week.svg' : 'Month.svg'}`} alt="" />
                                            <CoinImageText>
                                                <Tooltip title={vendorDetails?.rateInfo?.split('$')[0]}><div style={{ cursor: 'pointer' }}>{vendorDetails?.rateInfo?.split('$')[0] ? (vendorDetails?.rateInfo?.split('$')[0]?.length > 4 ? `${vendorDetails?.rateInfo?.split('$')[0]?.slice(0, 4)}...` : vendorDetails?.rateInfo?.split('$')[0]) : 0}</div></Tooltip>
                                            </CoinImageText>
                                        </CoinImageTab>
                                    </StyledCoin>
                                </StyledGridItem>
                            </SubContainer>
                            <StyledSkills>
                                <Grid container>
                                    <Grid item md={3.9}>
                                        <StyledPrimarySkillsContainer>
                                            <StyledPrimarySkills>
                                                <img src={'/Assets/Images/skillsPurple.svg'} height={25} width={25} alt={'primarySkills'} />
                                                Primary Skills
                                            </StyledPrimarySkills>
                                            <CardItems>
                                                <ToolsContainer>
                                                    {vendorDetails?.primarySkills?.map((tool, index) => (
                                                        <ToolBadge key={index}>{tool}</ToolBadge>
                                                    ))}
                                                </ToolsContainer>
                                            </CardItems>
                                        </StyledPrimarySkillsContainer>
                                    </Grid>
                                    <Grid item md={4}>
                                        <StyledPrimarySkillsContainer>
                                            <StyledPrimarySkills>
                                                <img src={'/Assets/Images/skillsPurple.svg'} height={25} width={25} alt={'primarySkills'} />
                                                Secondary Skills
                                            </StyledPrimarySkills>
                                            <CardItems>
                                                <ToolsContainer>
                                                    {vendorDetails?.secondarySkills?.map((tool, index) => (
                                                        <ToolBadge key={index}>{tool}</ToolBadge>
                                                    ))}
                                                </ToolsContainer>
                                            </CardItems>
                                        </StyledPrimarySkillsContainer>
                                    </Grid>
                                </Grid>
                            </StyledSkills>
                        </>
                    )}
                </StyledCard>

                <StyledContainer>
                    <StyledTabContainer>
                        <StyledTabs sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label="All CV's" />
                                <Tab label="Liked CV's" />
                                <Tab label="Disliked CV's" />
                                <Tab label="On Hold CV's" />
                            </Tabs>
                            <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                <Typography color={tableView ? themeColors?.black : themeColors?.purpleDark}>Card view</Typography>
                                <ToggelButton
                                    checked={tableView}
                                    onChange={() => setTableView(!tableView)}
                                />
                                <Typography color={!tableView ? themeColors?.black : themeColors?.purpleDark}>Table view</Typography>
                            </Typography>
                        </StyledTabs>
                        <StyledTabPannaels>
                            <SharedTabPannel value={tabValue} index={0}>
                                {tableView ?
                                    <SubmissionDetails submissionCvData={allCvs || []} spinner={spinner} handleCallCvApi={handleCallCvApi} /> :
                                    <VendorSubmissionCardView data={allCvs} spinner={CvFetchning} handleCallCvApi={handleCallCvApi} />
                                }
                            </SharedTabPannel>
                            <SharedTabPannel value={tabValue} index={1}>
                                {tableView ?
                                    <SubmissionDetails submissionCvData={likedCvs} spinner={spinner} handleCallCvApi={handleCallCvApi} /> :
                                    <VendorSubmissionCardView data={likedCvs} spinner={CvFetchning} handleCallCvApi={handleCallCvApi} />
                                }
                            </SharedTabPannel>
                            <SharedTabPannel value={tabValue} index={2}>
                                {tableView ?
                                    <SubmissionDetails submissionCvData={unlikedCvs} spinner={spinner} handleCallCvApi={handleCallCvApi} /> :
                                    <VendorSubmissionCardView data={unlikedCvs} spinner={CvFetchning} handleCallCvApi={handleCallCvApi} />
                                }
                            </SharedTabPannel>
                            <SharedTabPannel value={tabValue} index={3}>
                                {tableView ?
                                    <SubmissionDetails submissionCvData={onHoldCvs} spinner={spinner} handleCallCvApi={handleCallCvApi} /> :
                                    <VendorSubmissionCardView data={onHoldCvs} spinner={CvFetchning} handleCallCvApi={handleCallCvApi} />
                                }
                            </SharedTabPannel>
                        </StyledTabPannaels>
                    </StyledTabContainer>
                </StyledContainer>
            </Spinner>
        </MainContainer>
    );
};

const MainContainer = styled('div')({
    margin: '0 30px',
    paddingLeft: '30px',
    paddingRight: '30px',
    width: '95%',
    height: '100%',
    paddingTop: '24px',
    backgroundColor: '#FBFBFB',
    overflow: 'hidden',
    minHeight: '100vh'
});

const StyledCard = styled(Card)({
    marginTop: '30px'
})

const SubContainer = styled('div')({
    display: 'flex',
    gap: '30px',
    paddingLeft: '30px',
    paddingRight: '30px'
});

const StyledGridItem = styled(Grid)({
    flexBasis: '38%',
    boxSizing: 'border-box',
});

const StyledHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '8px',
    justifyContent: 'space-between',
});

const StyledGridExpandMoreIcon = styled(GridExpandMoreIcon)({
    fontSize: '40px',
});

const StyledKeyboardArrowUpOutlinedIcon = styled(KeyboardArrowUpOutlinedIcon)({
    fontSize: '40px'
})

const StyledSkills = styled('div')({
    display: 'flex',
})

const StyledPrimarySkillsContainer = styled('div')({
    margin: '0',
    paddingLeft: '30px',
    marginBottom: '20px'
})

const StyledPrimarySkills = styled(Typography)({
    display: 'flex',
    gap: '10px',
    fontSize: '20px',
    color: themeColors.purpleDark
})

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "10px"
})

const StyledTypography = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

const StyledAccordionSummary = styled(AccordionSummary)({
    background: 'white'
})

const StyledCoin = styled(Card)({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    position: 'relative',
});

const CoinImageTab = styled('div')({
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: 'auto',
});

const CoinImageText = styled('div')({
    position: 'absolute',
    color: themeColors.purpleMoneyShade,
    fontSize: '20px',
    fontWeight: 'bold',
    bottom: '46%',
    left: '48%',
    transform: 'translateX(-50%)',
    width: '20%',
    lineHeight: '16px',
    zIndex: 20,
});

const CardItems = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
});

const ToolsContainer = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    margin: "2px 0",
});

const ToolBadge = styled(Paper)({
    backgroundColor: themeColors.purpleLight,
    color: "black",
    boxShadow: "none",
    padding: "4px 8px",
    borderRadius: "9px",
    fontSize: "12px",
    display: "inline-block",
    margin: "4px",
});

const StyledContainer = styled(Box)({
    marginBottom: '30px'
})

const StyledTabContainer = styled(Card)({
    marginTop: '12px',
    padding: '15px'
})

const StyledTabPannaels = styled(Box)({
    paddingTop: '20px'
})

const StyledTabs = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
})

const ToggelButton = styled(Switch)(({ }) => ({
    width: 50,
    height: 20,
    padding: 0,
    display: 'flex',
    boxShadow: '10px,0px,0px,10px',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(32px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(30px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#ddcaf9',
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: `${themeColors.purpleDark} !important`,
                transition: '0.5sec'
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 15,
        height: 16,
        backgroundColor: themeColors.white,
        borderRadius: 10,
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: themeColors?.badgeGray,
        boxSizing: 'border-box',
    },
}));

export default VendorSubmissionDetails