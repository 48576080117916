import React from 'react'

import { Formik, Form, ErrorMessage, FieldInputProps, Field, FormikHelpers  } from 'formik';
import { TextField, Button, Grid, Box, Typography } from '@mui/material';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import { toast } from 'react-toastify';


export default function Contactus() {
    const navigate = useNavigate(); 
    
    interface FormValues {
        firstName: string;
        lastName: string;
        email: string;
        companyName: string;
        phoneNumber: string;
        query: string;
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Second name is required'),
        email: Yup.string().email('Invalid email format').required('Email is required'),
        companyName: Yup.string().required('Company name is required'),
        phoneNumber: Yup.string().required('Contact Number Required'),
        query: Yup.string().required('Please tell us how we can help'),
    });

    const initialValues: FormValues = {
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phoneNumber: '',
        query: '',
    };

    const onSubmit = async (values: FormValues,  { resetForm }: FormikHelpers<FormValues>) => {
        try {
            const response = await axios.post(
                'https://xak9-hweo-h6q9.n7.xano.io/api:ceD068cG:C2C-Backend/c2cLandingPageContactUs',
                values
            );
            toast.success('Form submitted successfully!');
            resetForm();

        } catch (error) {
            toast.error('An error occurred while submitting the form.');
        }
    };

    const handleLoginClick = () => {
        navigate('/signin'); 
    };

    const handleSignupClick = () => {
        navigate('/signup'); 
    };

    return (
        <div className='page2background'>
            <Box sx={{ flexGrow: 1 }} className="container-page4">
                <Grid spacing={2} className="maincontentp4">
                    <Grid spacing={4}>
                        <Typography
                            variant="h1"
                            component="h2"
                            className="contactHeading"
                        >
                            Contact Us
                        </Typography>
                        <div className='emailandInfo'>
                            <p>Email, call, or complete the form to learn how Talentxchange can help.</p>
                            <p className='info'>info@hiringhood.com</p>
                        </div>

                        <p className='contactp4'>+1 963 085 456</p>
                        <div className='LoginandSubmit' >
                            <p className='LetsGetstarted'>Let’s Get Started!</p>
                            <div className='loginGroup'>
                                <Button className="LoginP4"  onClick={handleLoginClick}>Login</Button>
                                <Button className="SignupP4" onClick={handleSignupClick}>SignUp</Button>
                            </div>

                        </div>
                    </Grid>
                    <Grid spacing={6} className='formforcontact'>
                        <Box sx={{ maxWidth: '600px', margin: 'auto', mt: 5 }} className='getintouchform'>
                            <Typography variant="h2" component="h1" gutterBottom className='getintouch' >
                                Get in Touch
                            </Typography>
                            <Typography variant="body1" gutterBottom className='formtext2'>
                                You can reach us anytime
                            </Typography>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({ handleSubmit }) => (
                                    <Form onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <div>
                                                    <Field
                                                        name="firstName"
                                                        placeholder="First Name"
                                                        className="customField"

                                                        style={{
                                                            color: '#FFF',
                                                            width: '100%',
                                                            padding: '10px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '74px',
                                                            backgroundColor: 'transparent',
                                                        }}
                                                    />
                                                    <ErrostyledMessage name="firstName" component="div"  />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <div>
                                                    <Field
                                                        name="lastName"
                                                        placeholder="Second Name"
                                                        className="customField"

                                                        style={{
                                                            color: '#FFF',
                                                            width: '100%',
                                                            padding: '10px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '74px',
                                                            backgroundColor: 'transparent',
                                                        }}
                                                    />
                                                    <ErrostyledMessage name="lastName" component="div" />
                                                </div>
                                            </Grid>
                                        </Grid>

                                        {/* Email input field */}
                                        <Box mt={2}>
                                            <div>
                                                <Field
                                                    name="email"
                                                    type="email"
                                                    placeholder="Email"
                                                    className="customField"
                                                    style={{
                                                        color: '#FFF',
                                                        width: '100%',
                                                        padding: '10px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '74px',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                                <ErrostyledMessage name="email" component="div" />
                                            </div>
                                        </Box>


                                        {/* Company name input field */}
                                        <Box mt={2}>
                                            <div>
                                                <Field
                                                    name="companyName"
                                                    placeholder="Company Name"
                                                    className="customField"
                                                    style={{
                                                        color: '#FFF',
                                                        width: '100%',
                                                        padding: '10px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '74px',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                                <ErrostyledMessage name="companyName" component="div"  />
                                            </div>
                                        </Box>
                                        <Box mt={2}>
                                            <div>
                                                <Field
                                                    name="phoneNumber"
                                                    type="text"
                                                    placeholder="+1 000 000 000"
                                                    className="customField"
                                                    style={{
                                                        color: '#FFF',
                                                        width: '100%',
                                                        padding: '10px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '74px',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                />
                                                <ErrostyledMessage name="phoneNumber" component="div"/>
                                            </div>
                                        </Box>
                                        {/* Contact message textarea */}
                                        <Box mt={2}>
                                            <div>
                                                <Field
                                                    as="textarea"
                                                    name="query"
                                                    rows={4}
                                                    className="customField"

                                                    placeholder="How can we help?"
                                                    style={{
                                                        color: '#FFF',
                                                        width: '100%',
                                                        padding: '10px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '14px',
                                                        backgroundColor: 'transparent', // Make background white
                                                    }}
                                                />
                                                <ErrostyledMessage name="query" component="div" />
                                            </div>
                                        </Box>

                                        {/* Submit button */}
                                        <Box mt={3} className='muisubmit'>
                                            <TextButton color="success" variant="contained" fullWidth type="submit">
                                                Submit
                                            </TextButton>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Grid>
                </Grid>

            </Box>

        </div>
    )
}

const TextButton = styled(Button)({
    background: 'linear-gradient(104deg, rgba(157, 66, 241, 0.20) 0%, rgba(92, 109, 255, 0.20) 77.6%), rgba(29, 29, 29, 0.05)'
    , color: '#fff',
    backdropFilter: 'blur(15)',
    borderRadius: '33px',
    '&:hover': {
    background: 'inherit',  
    boxShadow: 'none', 
  },

})
const ErrostyledMessage=styled(ErrorMessage)`
color:red;
margin-top:2px;
font-size:14px;
`