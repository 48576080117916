import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik, Field, ErrorMessage, FormikHelpers } from "formik";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./customstyles.css";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  Box,
  Typography,
  Grid,
  Slider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  FormHelperText,
  Chip,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import { CreateRFRModal, RFRDraftModal, User } from "api/resources/modals";
import DropdownSelector from "components/Multiselectdropdowns";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { ReactComponent as SaveIcon } from "../../../assets/images/save.svg";
import {
  useCreateRFRData,
  useDraftRFRData,
  useFetchRFqEmployeeLevel,
  useFetchRFqjobType,
  useFetchRfqpositon,
  useFetchRFQqualification,
  useFetchRFQsecodaryskills,
  useFetchRFRJobCategory,
  useFetchUserLocations,
  useFetchVisaType,
} from "pages/hooks";
import FileUploadComponent from "../Components/UploadFileField/Index";
import { marks, validationSchema } from "./constants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState, useAppSelector } from "core/store";
import BidRequestModal from "./Modal";
import { useNavigate } from "react-router-dom";
import Nestedmenumultiselect from "components/Multiselectnestedmenudropdowns";
import { themeColors } from "core/Theming/theme";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import MultiselectDropdown from "components/Multiselectdropdowns";

const CreateRFRForm = () => {
  const IsRfr = useSelector((state: RootState) => state.auth?.isRfr);
  const [isActiveFileDrop, setActiveFileDrop] = useState(false);
  const [isActiveJdText, setActiveJdText] = useState(false);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const today = dayjs();
  const { isVendor } = useAppSelector((store) => store.auth);
  const navigate = useNavigate();
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);
  const userId = userInfo?.user_id ?? "";
  const { levels = [], isLoading: loadingLevels } = useFetchRFqEmployeeLevel();
  const { secondaryskills = [], isLoading: loadingSkills } =
    useFetchRFQsecodaryskills();
  const { positions = [], isLoading: loadingPositions } = useFetchRfqpositon();
  const { qualifications = [], isLoading: loadingQualifications } =
    useFetchRFQqualification();
  const { visaType = [], isLoading: loadingVistype } = useFetchVisaType();
  const { jobs = [], isLoading: loadingJobs } = useFetchRFqjobType();
  const { jobCategory = [], isLoading: loadingJobCategories } =
    useFetchRFRJobCategory();


  const [bidModal, showBidModal] = useState<boolean>(false);
  const handleClose = () => showBidModal(false);
  const { postDraftRFRData } = useDraftRFRData({
    onSuccess: () => {
      toast.success("Requisition saved in draft");
    },
    onError: () => toast.error(" Please add Job title, try again"),
  });

  const { postCreateRFRData } = useCreateRFRData({
    onSuccess: () => {
      toast.success("Submitted Successfully");
      showBidModal(true);
    },
    onError: () => toast.error("Something went wrong, please try again"),
  });

  const { locations = [], isLoading: locationsLoading } =
    useFetchUserLocations();

  const [showMenu, setShowMenu] = useState(false);

  const textFieldRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (values: CreateRFRModal, actions: any) => {
    actions.setSubmitting(false);

    const payload: CreateRFRModal = {
      jobTitle: values.jobTitle,
      noOfPositions: values.noOfPositions,
      jobCategory: values.jobCategory,
      Locations: values.Locations,
      rfqClosingDate: values.rfqClosingDate,
      primarySkills: values.primarySkills,
      secondarySkills: values.secondarySkills,
      jobType: values.jobType,
      Qualifications: values.Qualifications,
      visaType: values.visaType,
      quickMessageToVendor: values.quickMessageToVendor,
      userId: userId,
      commission: values.commission,
      chargeType: values.chargeType,
      billRateFrom: values.billRateFrom,
      billRateTo: values.billRateTo,
      hourly: values.hourly,
      weekly: values.weekly,
      monthly: values.monthly,
      initialExperience: values.initialExperience,
      maxExperience: values.maxExperience,
      employeeLevel: values.employeeLevel,
      fileName: values.fileName,
      jobDescriptionText: values.jobDescriptionText,
      uploadJd: values.fileName ? values.fileName : values.jobDescriptionText,
    };
    postCreateRFRData(payload);
    actions.resetForm();

  };

  const handleDraft = (values: RFRDraftModal, actions: any) => {
    const payload: RFRDraftModal = {
      jobTitle: values.jobTitle,
      noOfPositions: values.noOfPositions,
      jobCategory: values.jobCategory,
      Locations: values.Locations,
      rfqClosingDate: values.rfqClosingDate,
      primarySkills: values.primarySkills,
      secondarySkills: values.secondarySkills,
      jobType: values.jobType,
      Qualifications: values.Qualifications,
      visaType: values.visaType,
      quickMessageToVendor: values.quickMessageToVendor,
      userId: userId,
      commission: values.commission,
      chargeType: values.chargeType,
      billRateFrom: values.billRateFrom,
      billRateTo: values.billRateTo,
      hourly: values.hourly,
      weekly: values.weekly,
      monthly: values.monthly,
      initialExperience: values.initialExperience,
      maxExperience: values.maxExperience,
      employeeLevel: values.employeeLevel,
      fileName: values.fileName,
      jobDescriptionText: values.jobDescriptionText,
      uploadJd: values.fileName ? values.fileName : values.jobDescriptionText,
    };

    postDraftRFRData(payload);
    actions.resetForm();
  };

  const initialValues: CreateRFRModal = {
    userId: "",
    jobTitle: "",
    noOfPositions: 0,
    Locations: [],
    jobCategory: "",
    Qualifications: [],
    primarySkills: [],
    secondarySkills: [],
    rfqClosingDate: null,
    jobType: "",
    visaType: [],
    employeeLevel: "",
    fileName: null,
    quickMessageToVendor: "",
    billRateFrom: 0,
    billRateTo: 0,
    commission: 0,
    chargeType: "",
    hourly: false,
    weekly: false,
    monthly: false,
    initialExperience: 0,
    maxExperience: 5,
    jobDescriptionText: "",
    uploadJd: "",
  };

  useEffect(() => { }, [
    locationsLoading,
    loadingSkills,
    loadingPositions,
    loadingQualifications,
    loadingJobs,
    loadingVistype,
    loadingLevels,
    loadingJobCategories,
  ]);

  const nestedLocations = useMemo(() => {
    if (!locations.length) return {};
    const result: Record<string, Record<string, Record<string, string[]>>> = {};
    locations.forEach((location) => {
      const { country, state, city, zipCode } = location;
      if (!result[country]) result[country] = {};
      if (!result[country][state]) result[country][state] = {};
      if (!result[country][state][city]) result[country][state][city] = [];
      result[country][state][city].push(zipCode);
    });
    return result;
  }, [locations]);

  const handleClick = () => setShowMenu(true);
  const handleClosed = () => setShowMenu(false);

  const secondaryData = useMemo(() => {
    return secondaryskills.map((secondaryskills) => secondaryskills.skill);
  }, [secondaryskills]);

  const qualificationsData = useMemo(() => {
    return qualifications.map(
      (qualifications) => qualifications.qualificationLevel
    );
  }, [qualifications]);

  const visaData = useMemo(() => {
    return visaType.map((visa) => visa.Visa);
  }, [visaType]);
  const handleModelButton = () => {
    navigate(isVendor ? "/vendor/myrequisitions" : "/employer/myrequisitions");
  };

  const fileRemove = () => {
    setActiveJdText(false);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleSubmit,
          resetForm,
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            <MainBox>
              <StyledMainTextBox>
                <DetailsText color="primary" variant="h4">
                  {
                    IsRfr ? (
                      <PreviousIcon
                        onClick={() =>
                          navigate(
                            isVendor
                              ? "/vendor/myrequisitions"
                              : "/employer/myrequisitions"
                          )
                        }
                      />
                    ) : (<PreviousIcon
                      onClick={() =>
                        navigate("/employer/dashboard")
                      }
                    />)
                  }

                  Requisition Details
                </DetailsText>
                <Button
                  component="button"
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    handleDraft(
                      values as unknown as RFRDraftModal,
                      { resetForm } as FormikHelpers<RFRDraftModal>
                    );
                  }}
                >
                  Save Draft
                </Button>
              </StyledMainTextBox>
              <FormContent>
                <Box mt={2}>
                  <Grid item md={6} mb={2}>
                    <UploadContainer>
                      <StyledLabelName>
                        Upload Job Description <RedIcon>*</RedIcon>
                      </StyledLabelName>
                      {/* <Button variant="outlined">Use Role Mapper</Button> */}
                    </UploadContainer>

                    <StyledJdContainer>
                      <FileuploadBox>
                        <FileUploadComponent
                          disabled={isActiveFileDrop}
                          onFileChange={(file: File | null) => {
                            if (file) {
                              setFieldValue("fileName", file);
                              setActiveJdText(true);
                            }
                          }}
                          removeFile={fileRemove}
                        />
                      </FileuploadBox>

                      <Typography sx={{ marginRight: "16px" }}>(or)</Typography>

                      <StyledTextareaAutosize
                        disabled={isActiveJdText}
                        onChange={(e) => {
                          const value = e.target.value;

                          setFieldValue("jobDescriptionText", value);

                          if (value.trim()) {
                            setActiveFileDrop(true);
                          } else {
                            // If textarea is empty, re-enable file upload
                            setActiveFileDrop(false);
                          }
                          // If the user clears the text area, re-enable the file upload
                          if (value.trim() === "") {
                            setActiveJdText(false);
                          }
                        }}
                        rows={9}
                      />


                      <FormHelperText
                        error={Boolean(touched.fileName && errors.fileName)}
                      >
                        <ErrorMessage name="fileName" />
                      </FormHelperText>
                    </StyledJdContainer>

                    <FormHelperText
                      error={Boolean(touched.fileName && errors.fileName)}
                    >
                      <ErrorMessage name="fileName" />
                    </FormHelperText>
                  </Grid>
                  <Grid container spacing={2}>

                    <Grid item md={6}>
                      <StyledLabelName>
                        Job Title<RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Field
                          as={TextField}
                          name="jobTitle"
                          variant="outlined"
                          error={touched.jobTitle && Boolean(errors.jobTitle)}
                        />

                        <ErrorMessages name="jobTitle" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <StyledLabelName>
                        Experience<RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <StyledStack>
                        <Slider
                          getAriaLabel={() => "experience"}
                          getAriaValueText={(value) => `${value}`}
                          defaultValue={[
                            values.initialExperience,
                            values.maxExperience,
                          ]}
                          valueLabelDisplay="auto"
                          marks={marks}
                          max={20}
                          onChange={(_, value) => {
                            if (Array.isArray(value)) {
                              const [initialExperience, maxExperience] = value;
                              setFieldValue(
                                "initialExperience",
                                initialExperience
                              );
                              setFieldValue("maxExperience", maxExperience);
                            }
                          }}
                        />
                      </StyledStack>
                    </Grid>

                    <Grid item md={6}>
                      <DropdownSelector
                        required
                        name="visaType"
                        label="Visa Type"
                        options={visaData}
                        placeholder="select or type and enter Qualifications"
                      />
                    </Grid>

                    <Grid item md={6}>
                      <FormControl fullWidth margin="none">
                        <StyledLabelName>
                          No of Positions<RedIcon>*</RedIcon>
                        </StyledLabelName>
                        <StyledTextField
                          as={TextField}
                          type="number"
                          variant="outlined"
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          onChange={(e) =>
                            setFieldValue("noOfPositions", e.target.value)
                          }
                        ></StyledTextField>
                        <ErrorMessages name="noOfPositions" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        required
                        name="Qualifications"
                        label="Qualification(s)"
                        options={qualificationsData}
                        placeholder="select or type and enter Qualifications"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControl fullWidth margin="none" required>
                        <StyledLabelName>
                          Location<RedIcon>*</RedIcon>
                        </StyledLabelName>
                        <TextField
                          fullWidth
                          value={values.Locations.join(", ")}
                          onClick={handleClick}
                          inputRef={textFieldRef}
                          InputProps={{
                            readOnly: true,
                            style: { color: "transparent" },
                          }}
                        />
                        <Dropdownstyle>
                          {values.Locations.map((location, index) => (
                            <Tooltip key={index} title={location}>
                              <Chip
                                key={index}
                                label={location}
                                onDelete={() => {
                                  const updatedLocations =
                                    values.Locations.filter(
                                      (loc) => loc !== location
                                    );
                                  setFieldValue("Locations", updatedLocations);
                                }}
                              />
                            </Tooltip>
                          ))}
                        </Dropdownstyle>
                        {showMenu && (
                          <Nestedmenumultiselect
                            data={nestedLocations}
                            formik={{ values, setFieldValue }}
                            anchorEl={textFieldRef}
                            onClose={handleClosed}
                          />
                        )}
                        <ErrorMessages name="Locations" component="div" />
                      </FormControl>
                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        required
                        name="primarySkills"
                        label="Primary Skill(s)"
                        options={secondaryData}
                        placeholder="select or type and enter Primary Skill(s)"
                      />

                    </Grid>
                    <Grid item md={6}>
                      <DropdownSelector
                        required
                        name="secondarySkills"
                        label="Secondary Skill(s)"
                        options={secondaryData}
                        placeholder="select or type and enter Secondary Skill(s)"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Typography>
                        Requisition Closing Date<RedIcon>*</RedIcon>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            value={null}
                            onChange={(newValue: Dayjs | null) =>
                              setFieldValue("rfqClosingDate", newValue ? newValue.local().format('YYYY-MM-DD') : null)
                            }
                            minDate={today}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <FormHelperText error={Boolean(errors.rfqClosingDate)}>
                        <ErrorMessage name="rfqClosingDate" />
                      </FormHelperText>
                    </Grid>

                    <Grid item md={6}>
                      <StyledLabelName>
                        Job Type <RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Autocomplete
                          freeSolo
                          options={jobs.map((job) => job.contractType)}
                          value={values.jobType}
                          onChange={(event, newValue) => {
                            setFieldValue("jobType", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => setFieldValue("jobType", e.target.value)}
                              label="Job Type"
                            />
                          )}
                        />
                        <ErrorMessages name="jobType" component="div" />
                      </FormControl>
                    </Grid>

                    <Grid item md={6}>
                      <StyledLabelName>
                        Job Category <RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Autocomplete
                          freeSolo
                          options={jobCategory.map((job) => job.jobCategory)}
                          value={values.jobCategory}
                          onChange={(event, newValue) => {
                            setFieldValue("jobCategory", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => setFieldValue("jobCategory", e.target.value)}
                              label="Job Category"
                            />
                          )}
                        />
                        <ErrorMessages name="jobCategory" component="div" />
                      </FormControl>
                    </Grid>


                    <Grid item md={6}>
                      <StyledLabelName>
                        Employee Level <RedIcon>*</RedIcon>
                      </StyledLabelName>
                      <FormControl fullWidth margin="none">
                        <Autocomplete
                          freeSolo
                          options={levels.map((level) => level.level)}
                          value={values.employeeLevel}
                          onChange={(event, newValue) => {
                            setFieldValue("employeeLevel", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => setFieldValue("employeeLevel", e.target.value)}
                              label="Employee Level"
                            />
                          )}
                        />
                        <ErrorMessages name="employeeLevel" component="div" />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid mt={3.5}>
                    <Typography variant="h6" mb={1}>
                      Rate Info
                    </Typography>
                    <RateInfoGrid container>
                      <Grid item md={12}>
                        <Box display={"flex"}>
                          <StyledLabelName>
                            Charge Type <RedIcon>*</RedIcon>:{" "}
                          </StyledLabelName>
                          <StyledFormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              onChange={(e) => {
                                const selectedValue = e.target.value;
                                setFieldValue("chargeType", selectedValue);
                                setFieldValue(
                                  "hourly",
                                  selectedValue === "hourly"
                                );
                                setFieldValue(
                                  "weekly",
                                  selectedValue === "weekly"
                                );
                                setFieldValue(
                                  "monthly",
                                  selectedValue === "monthly"
                                );
                              }}
                            >
                              <FormControlLabel
                                value="hourly"
                                control={<Radio />}
                                label="Hourly Rate"
                              />
                              <FormControlLabel
                                value="weekly"
                                control={<Radio />}
                                label="Weekly Rate"
                              />
                              <FormControlLabel
                                value="monthly"
                                control={<Radio />}
                                label="Monthly Rate"
                              />
                            </RadioGroup>
                            <FormHelperText
                              error={Boolean(
                                touched.chargeType && errors.chargeType
                              )}
                            >
                              <ErrorMessage name="chargeType" component="div" />
                            </FormHelperText>
                          </StyledFormControl>
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <FullDiv>
                          <CenteredTypography mb={1}>
                            Bill Rate <RedIcon>*</RedIcon>:
                          </CenteredTypography>
                          <StyledTextField
                            as={TextField}
                            type="number"
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) =>
                              setFieldValue("billRateFrom", e.target.value)
                            }
                          />
                          <CenteredTypography>to</CenteredTypography>

                          <StyledTextField
                            as={TextField}
                            type="number"
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) =>
                              setFieldValue("billRateTo", e.target.value)
                            }
                          />
                          <CenteredTypography>in USD</CenteredTypography>
                          <FormHelperText
                            error={Boolean(
                              touched.billRateFrom && errors.billRateFrom
                            )}
                          >


                          </FormHelperText>
                          <StyledErrorMessage>
                            <ErrorMessage name="billRateFrom" />
                          </StyledErrorMessage>
                          <StyledErrorMessage>
                            <ErrorMessage name="billRateTo" />
                          </StyledErrorMessage>
                        </FullDiv>
                      </Grid>
                      <Grid item md={6}>
                        <FullDiv>
                          <CenteredTypography>Commission :</CenteredTypography>
                          <StyledTextField
                            type="number"
                            variant="outlined"
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) =>
                              setFieldValue("commission", e.target.value)
                            }
                          />
                          <CenteredTypography>in USD</CenteredTypography>
                          <FormHelperText
                            error={Boolean(
                              touched.commission && errors.commission
                            )}
                          >
                            <ErrorMessage name="commission" />
                          </FormHelperText>
                        </FullDiv>
                      </Grid>
                    </RateInfoGrid>
                  </Grid>
                  <Grid item md={12}>
                    <Box mt={3.25}>
                      <StyledLabelName>
                        Quick message for vendors
                      </StyledLabelName>
                      <StyledTextareaAutosize
                        onChange={(e) =>
                          setFieldValue("quickMessageToVendor", e.target.value)
                        }
                        rows={4}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <HorizontalLine />
                  </Grid>
                </Box>
                <MainButtonDiv>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Get Submissions From Vendors
                  </StyledButton>
                </MainButtonDiv>
              </FormContent>
            </MainBox>
          </StyledForm>
        )}
      </Formik>

      <BidRequestModal
        open={bidModal}
        onButtonClick={() => handleModelButton()}
        handleClose={handleClose}
      />
    </>
  );
};

const RedIcon = styled("span")({
  color: themeColors.error,
});

const StyledErrorMessage = styled(Typography)`
  color: red;
  font-size: 0.875rem; /* Adjust the size as needed */
`;

const PreviousIcon = styled(ArrowBackIcon)({
  marginRight: "16px",
  cursor: "pointer",
});

const Dropdownstyle = styled(Box)({
  marginTop: "18px",
  width: "100%",
  "& .MuiChip-root": {
    width: "173px",
    borderRadius: " 5px",
    marginRight: "5px",
  },
});

const TextName = styled(`p`)({
  display: "flex",
  fontWeight: "500",
  color: themeColors.black,
  marginTop: "0px",
});

const StyledButton = styled(Button)({
  width: "auto",
  height: "38px",
  borderRadius: "12px",
});

const MainButtonDiv = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "48px",
});

const RateInfoGrid = styled(Grid)({
  backgroundColor: themeColors.white,
  borderRadius: "8px",
  padding: "10px",
});

const StyledForm = styled("form")({
  borderRadius: "8px",
  boxShadow: "0px 0px 4px 0px rgba(77, 108, 217, 0.75)",
  padding: "16px",
});
const UploadContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});
const StyledFormControl = styled(FormControl)({
  margin: "none",
  marginTop: "-8px",
  marginBottom: "auto",
  marginLeft: "8px",
});
const StyledStack = styled(Stack)({
  width: 500,
  spacing: 2,
  marginTop: "16px",
  flexDirection: "row",
});
const CenteredTypography = styled(Typography)({
  marginRight: "8px",
  alignSelf: "center",
});
const FullDiv = styled("div")({
  display: "flex",
  width: "100%",
});
const StyledTextField = styled(TextField)({
  marginRight: "8px",
  backgroundColor: themeColors.white,
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: themeColors.blackGray,
    },
    "&.Mui-focused fieldset": {
      borderColor: themeColors.primaryDark,
    },
  },
});

const StyledTextareaAutosize = styled("textarea")({
  width: "100%",
  backgroundColor: themeColors.white,
  borderRadius: "10px",
  resize: "vertical",
  overflow: "auto",
  padding: "8px",
  border: "1px dashed rgba(0, 0, 0, 0.50)",
  outline: "none",
  "&:focus": {
    border: "1px dashed rgba(0, 0, 0, 0.50)",
    outline: "none",
  },
  "&:hover": {
    border: "1px dashed rgba(0, 0, 0, 0.50)",
  },
  "&:disabled": {
    cursor: "no-drop",
    backgroundColor: themeColors.badgeGray
  }
});

const StyledMainTextBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const HorizontalLine = styled(Box)({
  width: "100%",
  height: "1px",
  marginTop: "30px",
  marginBottom: "30px",
});

const FileuploadBox = styled(Box)({
  marginRight: "16px",
  textAlign: "center",
  borderRadius: "10px",
  border: "1px dashed rgba(0, 0, 0, 0.50)",
  width: "100%",
  backgroundColor: themeColors.white,

});

const DetailsText = styled(Typography)({
  color: themeColors.black,
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 600,
});

const MainBox = styled(Box)({
  padding: "38px",
});

const ErrorMessages = styled(ErrorMessage)({
  color: "red",
});

const FormContent = styled(Box)({
  marginBottom: "40px",
});

const StyledLabelName = styled(Typography)({
  color: themeColors.grey,
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "150%",
  marginBottom: "8px",
});
const StyledJdContainer = styled(Box)({
  display: "inline-flex",
  width: "100%",
  borderRadius: "10px",
  backgroundColor: themeColors.badgeGray,
  padding: "16px",
  marginTop: "8px",
  alignItems: "center",
});
export default CreateRFRForm;
