import React, { useEffect } from "react";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { themeColors } from "core/Theming/theme";
import { useVendorYourJourney } from "pages/hooks";
// import cardColors from ''

interface YourJourneySoFarCardProps {
  vendorId: string
}

const VendorJourneySoFar: React.FC<YourJourneySoFarCardProps> = ({ vendorId }) => {
  const { VendorYourJourney, error, refetch, isLoading } = useVendorYourJourney(vendorId ? vendorId : '');

  return (
    <React.Fragment>
      <VendorJourneyCard cardColors={themeColors.badgeWarning} cardBorders={themeColors?.warning}>
        <Box display="flex" flexDirection="column" alignItems="space-between" width="100%">
          <StyledTypography display="flex" justifyContent="space-between" alignItems="center" width="100%">
            Requisitions <br /> Pending
            <img src="/Assets/Images/vendorJourneyPending.svg" alt="Pending" />
          </StyledTypography>
          <Typography variant="h2" sx={{ marginLeft: '6px' }}>
            {VendorYourJourney?.requisitions_pending || 0}
          </Typography>
        </Box>
      </VendorJourneyCard>

      <VendorJourneyCard cardColors={themeColors.badgeSuccess} cardBorders={themeColors?.warning}>
        <Box display="flex" flexDirection="column" alignItems="space-between" width="100%">
          <StyledTypography display="flex" justifyContent="space-between" alignItems="center" width="100%">
            Submissions <br /> Accepted
            <img src="/Assets/Images/vendorJourneyAccepted.svg" alt="Pending" />
          </StyledTypography>
          <Typography variant="h2" sx={{ marginLeft: '6px' }}>
            {VendorYourJourney?.submissions_accepted || 0}
          </Typography>
        </Box>
      </VendorJourneyCard>

      <VendorJourneyCard cardColors={themeColors.badgeError} cardBorders={themeColors?.warning}>
        <Box display="flex" flexDirection="column" alignItems="space-between" width="100%">
          <StyledTypography display="flex" justifyContent="space-between" alignItems="center" width="100%">
            Submissions <br /> Declined
            <img src="/Assets/Images/vendorJourneyDeclined.svg" alt="Pending" />
          </StyledTypography>
          <Typography variant="h2" sx={{ marginLeft: '6px' }}>
            {VendorYourJourney?.submissions_declined || 0}
          </Typography>
        </Box>
      </VendorJourneyCard>
      <VendorJourneyCard cardColors={themeColors.purpleLight} cardBorders={themeColors?.warning}>
        <Box display="flex" flexDirection="column" alignItems="space-between" width="100%">
          <StyledTypography display="flex" justifyContent="space-between" alignItems="center" width="100%">
            Interest Received <br /> For Submissions
            <img src="/Assets/Images/vendorJourneyInterestRecieved.svg" alt="Pending" />
          </StyledTypography>
          <Typography variant="h2" sx={{ marginLeft: '6px' }}>
            {VendorYourJourney?.submissions_declined || 0}
          </Typography>
        </Box>
      </VendorJourneyCard>
    </React.Fragment>
  )
};

const VendorJourneyCard = styled('div') <{ cardColors?: string, cardBorders?: string }>`
  height: 120px;
  width: 18vw;
  background-color: ${props => props.cardColors || themeColors.white};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const StyledTypography = styled(Typography)`
  color: ${themeColors.blackGray};
  font-family: Roboto;
  font-size: 17px;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  width: 100%
`;

export default VendorJourneySoFar;
