import React, { useEffect, useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  Stack,
  TooltipProps,
  Tooltip,
  tooltipClasses,
  InputBase,
  Chip,
  Typography,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import {
  useCvLikeOrDislike,
  useGetCvDetails,
  useGetSubmissionDetails,
} from "pages/hooks";
import { SubmissionCvModal } from "api/resources/modals";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import { ReactComponent as Uncolor } from "../../../assets/icons/TableviewUncolorIcon.svg";
import { ReactComponent as Check } from "../../../assets/icons/TableviewCheckicon.svg";
import { ReactComponent as ColorCheck } from "../../../assets/icons/TableviewColorCheckicon.svg";
import { ReactComponent as Download } from "../../../assets/icons/Tableviewdowloadicon.svg";
import { ReactComponent as Eyesvg } from "../../../assets/icons/Eye Open.svg";
import { ReactComponent as Shape } from "../../../assets/icons/TableviewShapeicon.svg";
import { ReactComponent as Colorcheck } from "../../../assets/icons/RfrIcon/Coloredchecked.svg";
import { ReactComponent as Coloruncheck } from "../../../assets/icons/RfrIcon/Colored Unchecked.svg";
import { ReactComponent as VisaIcon } from "../../../assets/images/visa.svg";
import { ReactComponent as Location } from "../../../assets/icons/Location.svg";
import { ReactComponent as EmployerLevel } from "../../../assets/images/userCheck.svg";
import { ReactComponent as Commission } from "../../../assets/images/commission.svg";
import { ReactComponent as RateInfoicon } from "../../../assets/images/rateinfoNote.svg";
import { ReactComponent as Skills } from "../../../assets/images/icons/skills.svg";
import SearchIcon from "@mui/icons-material/Search";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import { toast } from "react-toastify";
import { ViewCVS } from "./Cv";
import DetailsViewModal from "../Components/Viewmodal";
import InfoBox from "../Components/Info";

const Submissionstable: React.FC = () => {
  const { sumission_id: submmissionID } = useParams();

  const {
    cvDetails = [],
    refetch: refetcSubmissionDetails,
    isLoading,
  } = useGetCvDetails(submmissionID);

  const { submissionsDetails, refetch: refetcSubmissionDetail } =
    useGetSubmissionDetails(submmissionID);

  const { cvLikeOrDislike } = useCvLikeOrDislike({
    onError: () => toast.error("Error Date requisition:"),
    onSuccess: () => {
      refetcSubmissionDetails();
      refetcSubmissionDetail();
    },
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const [selectionModel, setSelectionModel] = useState<SubmissionCvModal[]>([]);

  const [viewSubDetailsModal, setViewSubDetailsModal] =
    useState<boolean>(false);

  const safeJoin = (array: string[] | undefined) =>
    array ? array.join(", ") : "N/A";

  const maxSkillsToShow = 1;
  const visibleSkill = submissionsDetails?.Visa_Type.slice(0, maxSkillsToShow);
  const hiddenSkill = submissionsDetails?.Visa_Type.slice(maxSkillsToShow);


  const infoPrimaryskill =submissionsDetails && Array.isArray(submissionsDetails?.prime_skills)
  ? submissionsDetails.prime_skills.slice(0, maxSkillsToShow)
  : "";
  const hiddenPrimaryskill= submissionsDetails && Array.isArray(submissionsDetails?.prime_skills)
  ? submissionsDetails.prime_skills.slice(maxSkillsToShow):"";

  const infoSecondaryskill=submissionsDetails && Array.isArray(submissionsDetails?.second_skills)
  ? submissionsDetails.second_skills.slice(0, maxSkillsToShow):"";
  const hiddenSecondaryskill=submissionsDetails && Array.isArray(submissionsDetails?.second_skills)
  ? submissionsDetails.second_skills.slice(maxSkillsToShow):"";

  const [open, setOpen] = useState(true);

  const [cvid, setCvID] = useState("");

  useEffect(() => {
    refetcSubmissionDetails();
  }, [refetcSubmissionDetails, selectedTab, open]);

  const onHide = () => {
    setOpen(true);
  };

  const headers = [
    {
      title: "Cv ID",
      name: "cv_id",
      cellWidth: "200px",
      filterType: "number",
      filter: true,

      customComponent: (row: SubmissionCvModal) => {
        return (
          <Jobtitles>
            <StyledText to={""}>{row.cv_id}</StyledText>
          </Jobtitles>
        );
      },
    },
    {
      title: "Cv name",
      filter: true,
      cellWidth: "200px",
      filterType: "text",
      name: "cv_name",
    },
    ...(selectedTab === 0
      ? [
          {
            title: "Status",
            cellWidth: "200px",
            name: "status",
            filter: true,
            filterType: "text",
            customComponent: (row: SubmissionCvModal) => {
              return (
                <>
                  {row.status === "pending" ? (
                    <StyledButtonPending>Pending</StyledButtonPending>
                  ) : row.status === "Disliked" ? (
                    <StyledButtonRejected>Disliked</StyledButtonRejected>
                  ) : row.status === "Liked" ? (
                    <StyledButtonAccept>Liked</StyledButtonAccept>
                  ) : (
                    " -"
                  )}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "Last Job",
      filter: true,
      cellWidth: "250px",
      filterType: "text",
      name: "last_job_title",
    },
    {
      title: "Qualification",
      filter: true,
      cellWidth: "300px",
      filterType: "text",
      name: "higher_qualification",

      customComponent: (row: SubmissionCvModal) => {
        return (
          <p
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {row?.higher_qualification}
          </p>
        );
      },
    },
    {
      title: "Experience",
      filter: true,
      cellWidth: "200px",
      filterType: "text",
      name: "fullExperience",
    },
    {
      title: "Primary Skills",
      filter: true,
      cellWidth: "300px",
      filterType: "text",
      name: "prime_skills",

      customComponent: (row: SubmissionCvModal) => {
        const maxSkillsToShow = 1;
        const { prime_skills = [] } = row;
        const visibleSkills = prime_skills.slice(0, maxSkillsToShow);
        const hiddenSkills = prime_skills.slice(maxSkillsToShow);

        return (
          <Stacks direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Skil label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Skil label="..." />
              </Tooltip>
            )}
          </Stacks>
        );
      },
    },
    {
      title: "Secondary Skills",
      filter: true,
      cellWidth: "300px",
      filterType: "text",
      name: "second_skills",

      customComponent: (row: SubmissionCvModal) => {
        const maxSkillsToShow = 1;
        const { second_skills = [] } = row;
        const visibleSkills = second_skills.slice(0, maxSkillsToShow);
        const hiddenSkills = second_skills.slice(maxSkillsToShow);
        return (
          <Stacks direction="row" spacing={1} mt={1} ml={1}>
            {visibleSkills.map((skill, index) => (
              <Tooltip key={index} title={skill}>
                <Secondaryskill label={skill} />
              </Tooltip>
            ))}

            {hiddenSkills.length > 0 && (
              <Tooltip title={hiddenSkills.join(", ")}>
                <Secondaryskill label="..." />
              </Tooltip>
            )}
          </Stacks>
        );
      },
    },
  ];
  const actionItmes = [
    {
      type: "button",
      name: "actions",
      handleClick: (action: string, item: SubmissionCvModal) => {},
      customActionComponent: (row: SubmissionCvModal) => {
        return (
          <>
            {selectedTab === 0 ? (
              <Iconcontainer>
                {row.status === "Liked" ? (
                  <Likecontainer>
                    <LightTooltip
                      textColor={themeColors.primaryAlt}
                      title="Like"
                      arrow
                    >
                      <ColorcheckIcon />
                    </LightTooltip>
                  </Likecontainer>
                ) : (
                  <LightTooltip
                    textColor={themeColors.primaryAlt}
                    title="Like"
                    arrow
                  >
                    <Check
                      onClick={() => handleCheckLike(row.cv_id, "Liked")}
                    />
                  </LightTooltip>
                )}
                {row.status === "Disliked" ? (
                  <LightTooltip
                    textColor={themeColors.primaryAlt}
                    title="DisLike"
                    arrow
                  >
                    <ColorUncheckIcon />
                  </LightTooltip>
                ) : (
                  <LightTooltip
                    textColor={themeColors.primaryAlt}
                    title="DisLike"
                    arrow
                  >
                    <UncheckIcon
                      onClick={() => handleCheckDisLike(row.cv_id, "Disliked")}
                    />
                  </LightTooltip>
                )}

                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Download"
                  arrow
                >
                  <DownloadIcon onClick={() => handleDownloadCv(row.cv_id)} />
                </LightTooltip>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="View"
                  arrow
                >
                  <View
                    onClick={() => {
                      setOpen(false);
                      setCvID(row?.cv_id);
                    }}
                  />
                </LightTooltip>
              </Iconcontainer>
            ) : selectedTab === 1 ? (
              <LikesTab>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Dislike"
                  arrow
                >
                  <UncheckIcon
                    onClick={() => handleCheckDisLike(row.cv_id, "Disliked")}
                  />
                </LightTooltip>

                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Download"
                  arrow
                >
                  <DownloadIcon onClick={() => handleDownloadCv(row.cv_id)} />
                </LightTooltip>

                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="View"
                  arrow
                >
                  <View
                    onClick={() => {
                      setOpen(false);
                      setCvID(row?.cv_id);
                    }}
                  />
                </LightTooltip>
              </LikesTab>
            ) : selectedTab === 2 ? (
              <Iconcontainer>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Like"
                  arrow
                >
                  <Check onClick={() => handleCheckLike(row.cv_id, "Liked")} />
                </LightTooltip>

                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Download"
                  arrow
                >
                  <DownloadIcon onClick={() => handleDownloadCv(row.cv_id)} />
                </LightTooltip>

                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="View"
                  arrow
                >
                  <View
                    onClick={() => {
                      setOpen(false);
                      setCvID(row?.cv_id);
                    }}
                  />
                </LightTooltip>
              </Iconcontainer>
            ) : (
              <Iconcontainer>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Like"
                  arrow
                >
                  <Check onClick={() => handleCheckLike(row.cv_id, "Liked")} />
                </LightTooltip>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="DisLike"
                  arrow
                >
                  <UncheckIcon
                    onClick={() => handleCheckDisLike(row.cv_id, "Disliked")}
                  />
                </LightTooltip>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="Download"
                  arrow
                >
                  <DownloadIcon onClick={() => handleDownloadCv(row.cv_id)} />
                </LightTooltip>
                <LightTooltip
                  textColor={themeColors.primaryAlt}
                  title="View"
                  arrow
                >
                  <View
                    onClick={() => {
                      setOpen(false);
                      setCvID(row?.cv_id);
                    }}
                  />
                </LightTooltip>
              </Iconcontainer>
            )}
          </>
        );
      },
    },
  ];

  const handleDownloadCv = (cvId: string) => {
    const cvUrl = cvId;
    if (cvUrl) {
      fetch(cvUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${cvId}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        })
        .catch(() => {
          toast.error("Failed to download CV");
        });
    }
  };

  const handleCheckLike = (cvId: string, status: string) => {
    cvLikeOrDislike({
      cvID: cvId,
      status: status,
    });
    toast.success("Liked Cv Successfully!");
  };

  const handleCheckDisLike = (cvId: string, status: string) => {
    cvLikeOrDislike({
      cvID: cvId,
      status: status,
    });
    toast.success("Unliked Cv Successfully!");
  };

  const handleMultiLikes = () => {
    const Ids = selectionModel.map((id) => id.cv_id);
    if (Ids.length > 0) {
      Ids.forEach((Ids) =>
        cvLikeOrDislike({
          cvID: Ids,
          status: "Liked",
        })
      );

      toast.success("Liked Cvs Successfully!");
      refetcSubmissionDetails();
    }
  };

  const handleMultidownload = () => {
    const cvList = selectionModel;
    cvList.forEach((item) => {
      const cvUrl = item.cv;
      if (cvUrl) {
        fetch(cvUrl)
          .then((response) => response.blob())
          .then((blob) => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${item.id}.pdf`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
          })
          .catch(() => {
            toast.error(`Failed to download CV with ID: ${item.id}`);
          });
      }
    });
  };

  const handleMultiDislikes = () => {
    const Ids = selectionModel.map((id) => id.cv_id);
    if (Ids.length > 0) {
      Ids.forEach((Ids) =>
        cvLikeOrDislike({
          cvID: Ids,
          status: "Disliked",
        })
      );

      toast.success("Unliked Cvs Successfully!");
      refetcSubmissionDetails();
    }
  };

  const jobsList = selectedTab === 0 ? cvDetails : cvDetails;

  const filteredJobs = Array.isArray(jobsList)
    ? jobsList.filter((job: { status: string }) => {
        switch (selectedTab) {
          case 1:
            return job.status === "Liked";
          case 2:
            return job.status === "Disliked";
          case 3:
            return job.status === "pending";
          default:
            return true;
        }
      })
    : [];
  const handleDoBulk = (selectedItems: SubmissionCvModal[]) => {
    setSelectionModel(selectedItems);
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };
  if (isLoading) {
    return <Spinner show={true} />;
  }
  return (
    <>
      {open ? (
        <MainContent>
          <Middlecontainer>
            <MainTabs>
              <MainGridbox>
                <MainAppbar position="static">
                  <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    aria-label="job tabs"
                  >
                    <Tab label="All Cvs" />
                    <Tab label="Liked CVs" />
                    <Tab label="Disliked CVs" />
                    <Tab label="Pending CVs" />
                  </Tabs>
                </MainAppbar>
                <MainGridcontainer>
                  <LeftsiteContainer>
                    <Leftsitecontent>
                      <LeftcontentText>
                        {selectionModel.length} of {filteredJobs.length}{" "}
                        Selected
                      </LeftcontentText>
                      {selectedTab === 2 ||
                      selectedTab === 0 ||
                      selectedTab === 3 ? (
                        <LightTooltip
                          textColor={themeColors.primaryAlt}
                          title="Like"
                          arrow
                        >
                          <Accept onClick={handleMultiLikes} />
                        </LightTooltip>
                      ) : (
                        ""
                      )}
                      {selectedTab === 1 ||
                      selectedTab === 0 ||
                      selectedTab === 3 ? (
                        <LightTooltip
                          textColor={themeColors.primaryAlt}
                          title="Unlike"
                          arrow
                        >
                          <Close onClick={handleMultiDislikes} />
                        </LightTooltip>
                      ) : (
                        ""
                      )}
                      {selectedTab === 2 ||
                      selectedTab === 3 ||
                      selectedTab === 1 ||
                      selectedTab === 0 ? (
                        <LightTooltip
                          textColor={themeColors.primaryAlt}
                          title="Download"
                          arrow
                        >
                          <DownloadIcon onClick={handleMultidownload} />
                        </LightTooltip>
                      ) : (
                        ""
                      )}
                    </Leftsitecontent>
                    <RightSiteICon>
                      {/* <Search id="search">
                    <SearchIconWrapper>
                      <SearchLogo />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search> */}
                      {/* <FunnelIcon onClick={()=>(toast.info("Comming Soon"))} /> */}
                      <StyledButton
                        variant="contained"
                        onClick={() => setViewSubDetailsModal(true)}
                      >
                        View Submission Details
                      </StyledButton>

                      <DetailsViewModal
                        SubmissionId={submmissionID as string}
                        openModal={viewSubDetailsModal}
                        title="Submission Id :"
                        onClose={() => setViewSubDetailsModal(false)}
                      >
                        {submissionsDetails && (
                          <Box sx={{ padding: "16px" }}>
                            <Visacontainer>
                              <VisaleftContainer>
                                <VisaIcon />
                                <CardKey>Visa type: </CardKey>
                              </VisaleftContainer>
                              {visibleSkill?.map((skill, index) => (
                                <Tooltip key={index} title={skill}>
                                  <Visaskills label={skill} />
                                </Tooltip>
                              ))}
                              {hiddenSkill && hiddenSkill?.length > 0 && (
                                <Tooltip title={hiddenSkill.join(", ")}>
                                  <Visaskills label="..." />
                                </Tooltip>
                              )}
                            </Visacontainer>

                            <InfoBox
                              icon={Location}
                              label="Location"
                              value={safeJoin(submissionsDetails?.locations)}
                            />
                            <InfoBox
                              icon={EmployerLevel}
                              label="Employer Level"
                              value={
                                submissionsDetails?.Employer_Level ?? "N/A"
                              }
                            />
                            <InfoBox
                              icon={Commission}
                              label="Commission"
                              value={`$${submissionsDetails?.commission}`}
                            />
                            <InfoBox
                              icon={RateInfoicon}
                              label="Rate card"
                              value={
                                submissionsDetails?.billRateStatus ?? "N/A"
                              }
                            />
                            <Skillcontainers>
                              <VisaleftContainer>
                                <Skills />
                                <CardKey>Primary Skills:</CardKey>
                              </VisaleftContainer>
                              {infoPrimaryskill?infoPrimaryskill?.map((skill, index) => (
                                <Tooltip key={index} title={skill}>
                                  <Infoprimaryskil label={skill} />
                                </Tooltip>
                              )):""}
                              {hiddenPrimaryskill && hiddenPrimaryskill?.length > 0 && (
                                <Tooltip title={hiddenPrimaryskill.join(", ")}>
                                  <Infoprimaryskil label="..." />
                                </Tooltip>
                              )}
                            </Skillcontainers>
                            <Skillcontainers>
                              <VisaleftContainer>
                                <Skills />
                                <CardKey>Secondary Skills:</CardKey>
                              </VisaleftContainer>
                              {infoSecondaryskill?infoSecondaryskill?.map((skill, index) => (
                                <Tooltip key={index} title={skill}>
                                  <Infosecondaryskil label={skill} />
                                </Tooltip>
                              )):""}
                              {hiddenSecondaryskill && hiddenSecondaryskill?.length > 0 && (
                                <Tooltip title={hiddenSecondaryskill.join(", ")}>
                                  <Infosecondaryskil label="..." />
                                </Tooltip>
                              )}
                            </Skillcontainers>
                          </Box>
                        )}
                      </DetailsViewModal>
                    </RightSiteICon>
                  </LeftsiteContainer>
                  <Maingridtable>
                    <CustomTable>
                      <ReusableTable
                        headers={headers}
                        data={filteredJobs}
                        itemsPerPage={10}
                        spinner={isLoading}
                        handleDoBulk={handleDoBulk}
                        checkBox={true}
                        headerColor={themeColors.primaryLight}
                        inputBorder={themeColors.primaryDark}
                        actions={actionItmes}
                        actionsCellWidth={"200px"}
                      />
                    </CustomTable>
                  </Maingridtable>
                </MainGridcontainer>
              </MainGridbox>
            </MainTabs>
          </Middlecontainer>
        </MainContent>
      ) : (
        <ViewCVS onHide={onHide} cvID={cvid} />
      )}
    </>
  );
};


const Skillcontainers=styled(Box)({
  display:'flex',
  flexDirection:'column'
})
const Stacks = styled(Stack)({
  overflow: "hidden",
});

const Infoprimaryskil = styled(Chip)({
  background: themeColors.primaryLight,
  color: themeColors.primaryDark,
  fontWeight: "500",
  width:'100px',
  marginLeft:'30px',
});

const Infosecondaryskil = styled(Chip)({
  background: themeColors.purpleLight,
  color: themeColors.purpleDark,
  fontWeight: "500",
  width:'100px',
  marginLeft:'30px',
});

const CustomTable = styled(Box)({
  "& .MuiTableBody-root ": {
    "& .MuiTableCell-root": {
      padding: "0px !important",
    },
  },
  marginTop: "10px",
});

 const Visaskills = styled(Chip)({
  background: 'none',
  color: 'none',
  fontSize:'19px'
});
const CardKey = styled(Typography)({
  marginLeft: "8px",
  color: themeColors.primaryDark,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
});
const Visacontainer=styled(Box)({
  display:'flex'
});
const VisaleftContainer=styled(Box)({
  display:'flex'
})
export const Likecontainer = styled(Box)({
  padding: "10px",
});
const Skil = styled(Chip)({
  background: themeColors.primaryLight,
  color: themeColors.primaryDark,
  fontWeight: "500",
});

const Secondaryskill = styled(Chip)({
  background: themeColors.purpleLight,
  color: themeColors.purpleDark,
  fontWeight: "500",
});

const SearchLogo = styled(SearchIcon)({
  color: themeColors.primaryDark,
});

export const StyledButtonAccept = styled(Button)({
  color: themeColors.success,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.successLight,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonPending = styled(Button)({
  color: themeColors.darkYellow,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.lightYellow,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightYellow,
    color: themeColors.darkYellow,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

export const StyledButtonRejected = styled(Button)({
  color: themeColors.darkRed,
  width: "102px",
  borderRadius: "30px",
  backgroundColor: themeColors.lightRed,
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.lightRed,
    color: themeColors.darkRed,
    border: "none",
  },
  fontSize: "12px",
  marginLeft: "16px",
});

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  color: themeColors.black,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  background: themeColors.white,
  color: themeColors.black,

  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "200px",
    borderRadius: "30px",
    border: `1px solid ${themeColors.primaryLightDark}`,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const FunnelIcon = styled(Shape)({
  marginTop: "5px",
  cursor: "pointer",
});
const StyledButton = styled(Button)({
  borderRadius: "5px",
  width: "198px",
  height: "36px",
  fontSize: "12px",
  marginRight: "16px",
});
const Accept = styled(ColorCheck)({
  cursor: "pointer",
});

export const View = styled(Eyesvg)({
  cursor: "pointer",
  marginLeft: "16px",
});

export const UncheckIcon = styled(Uncolor)({
  cursor: "pointer",
});

export const ColorUncheckIcon = styled(Coloruncheck)({
  cursor: "pointer",
  marginLeft: "0px",
  height: "28px",
  width: "30px",
});

export const ColorcheckIcon = styled(Colorcheck)({
  cursor: "pointer",
  marginRight: "15px",
  height: "28px",
  width: "30px",
});

const MainTabs = styled(Box)({
  width: "100%",
});

export const DownloadIcon = styled(Download)({
  cursor: "pointer",
  marginLeft: "16px",
});
export const Iconcontainer = styled(`div`)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "10px",
});

const LikesTab = styled(`div`)({
  marginTop: "13px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "10px",
});

const Close = styled(Uncolor)({
  marginLeft: "16px",
  cursor: "pointer",
});

export const StyledButtonAccepted = styled(Button)({
  color: themeColors.success,
  padding: "5px 20px",
  borderRadius: "8px",
  backgroundColor: themeColors.successLight,
  textDecoration: "underline",
  border: "none",
  "&:hover": {
    backgroundColor: themeColors.successLight,
    color: themeColors.success,
    border: "none",
  },
  marginLeft: "16px",
});

const Jobtitles = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
});

const StyledText = styled(Link)({
  color: themeColors.primaryDark,
  textDecoration: "underline",
  cursor: "pointer",
});

const LeftcontentText = styled(`p`)({
  marginLeft: "15px",
  color: themeColors.mediumDark,
  marginRight: "15px",
});
const LeftsiteContainer = styled(Box)({
  margin: "0px 7px",
  display: "flex",
  alignItems: "center",
  paddingTop: "6px",
});
const Leftsitecontent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  height: "42px",
});
const RightSiteICon = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "15px",
});
const MainGridcontainer = styled(Box)({
  border: `1px solid ${themeColors.primaryLightDark}  !important`,
  borderRadius: "8px",
  borderBottomRightRadius: "1px",
  borderBottomLeftRadius: "1px",
  boxShadow: `0px 0px 4px 0px  ${themeColors.shadowColor}`,
});

const Maingridtable = styled(Box)({
  display: "grid",
  gridAutoFlow: "column",
  gridAutoColumns: "100%",
});

const MainGridbox = styled(Box)({
  width: "100%",
});

const MainAppbar = styled(AppBar)({
  background: "none",
  width: "870px",
  boxShadow: "none",
  fontWeight: "400",
  "& .MuiTabs-flexContainer ": {
    display: "flex",
    justifyContent: "flex-start",
  },
  marginLeft: "65px",
});
const Middlecontainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
});
const MainContent = styled(Box)({
  width: "100%",
});

interface LightTooltipProps extends TooltipProps {
  textColor?: string;
}
export const LightTooltip = styled(
  ({
    className,
    textColor = themeColors.grey,
    ...props
  }: LightTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, textColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "6px",
    color: textColor,
    boxShadow: `0 0 0.45rem ${themeColors.primaryAlt}`,
    fontSize: 12,
  },
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.common.white,
  },
}));

export default Submissionstable;
