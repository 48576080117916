import styled from "@emotion/styled";
import { ExpandMore as GridExpandMoreIcon } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { AccordionSummary, Box, Button, Card, Checkbox, Grid, IconButton, Modal, Paper, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { VendorRaisedSubmissions } from "api/resources/modals";
import { SharedTabPannel } from "components/SharedTabPannel";
import MultipleFileUpload from "components/multipleFileUploadComponent/MultipleFileUpload";
import PageHeader from "components/pageHeaders/PageHeader";
import ReusableTable from "components/reusableFIlterTable/ReusableTable";
import Spinner from "components/spinner/Spinner";
import { themeColors } from "core/Theming/theme";
import { useAppSelector } from "core/store";
import { useGetVendorSubmissionCvs, useRaisedSubmissions, useRFQDescription, useVendorAddMultipleCv } from "pages/hooks";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import VendorDescription from "./Description/Description";
import DetailsCard from "./DetailsCard";
import SubmissionForm, { SubmissionFormValues } from "./SubmissionForm/SubmissionForm";
import SubmissionDetails from "./submissionDetails/SubmissionDetails";

const RmpDetails = () => {
    const userId = useAppSelector((state) => state.auth?.userInfo?.user_id);
    const userDetails = useAppSelector((state) => state.auth.userInfo?.general_profile)
    const params = useParams();
    const rfqId = params?.rmp_id;
    const [expanded, setExpanded] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [spinner, showSpinner] = useState(true)
    const [details, setDetails] = useState<{ label: string, value: string | number | null, icon: string }[] | []>([])
    const { rfqDescription, isLoading, error } = useRFQDescription(rfqId ? rfqId : '');
    const { raisedSubmissions, refetch, isLoading: RaisedSubmissionLoading } = useRaisedSubmissions(rfqId || '', userId || '')
    const [sortedSubmissions, setSortedSubmissions] = useState<VendorRaisedSubmissions[]>([]);
    const billRate = rfqDescription?.details?.rfq_details?.rate_info?.bill_rate
    const [submissionForm, setSubmissionForm] = useState<SubmissionFormValues>()
    const [previewTabSubId, setPreviewTabSubId] = useState<string | number | null>('')
    const [modal, showModal] = useState(false)
    const { submissonCvList, isLoading: getCvDataLoader, refetch: previewTabRefetchData } = useGetVendorSubmissionCvs(previewTabSubId || '')
    const [resetKey, setResetKey] = useState<string>('');
    const [uploadCvs, setUploadCvs] = useState<string[]>([])
    const [uploadCvSubId, setUploadCvSubId] = useState<string | number>('')
    const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
    const [showEmailIcon, setShowEmailIcon] = useState(false)
    const [showCompanyIcon, setShowCompanyIcon] = useState(false)
    const [declarationCheck, setDeclarationCheck] = useState(false)
    const { updateVendorSubmissionCvs, isLoading: cvUploadLoading } = useVendorAddMultipleCv({
        onSuccess: () => {
            toast.success("CV added successfully!");
        },
        onError: () => {
            toast.error(error?.message);
        },
    });

    const headers = [
        {
            title: 'Submission Id', filter: true, filterType: 'number', cellWidth: '200px', name: 'C2C_Vendor_Bid_Id', handleFieldClick: (action: string, item: VendorRaisedSubmissions) => {
                setTabValue(3)
                setPreviewTabSubId(item?.C2C_Vendor_Bid_Id)
            }, customComponent: (row: VendorRaisedSubmissions) => {
                return (<Typography color={themeColors?.primaryDark}><u>{row?.C2C_Vendor_Bid_Id}</u></Typography>)
            }
        },
        { title: 'Submission Date', cellWidth: '200px', filter: true, filterType: 'date', name: 'created_at', max: new Date().toISOString().split('T')[0] },
        {
            title: 'Visa Type', filter: true, cellWidth: '200px', filterType: 'text', name: 'Visa_Type', customComponent: (row: VendorRaisedSubmissions) => {
                const data = row?.Visa_Type && row?.Visa_Type.length > 0 ? row?.Visa_Type.join(', ') : '--'
                return (
                    data ? <Tooltip PopperProps={{ style: { zIndex: 0 } }} title={data}><div>{data?.length > 20 ? `${data?.slice(0, 20)}....` : data}</div></Tooltip> : <div>--</div>
                )
            }
        },
        { title: 'Employee Level', filter: true, cellWidth: '200px', filterType: 'text', name: 'Employer_Level' },
        {
            title: 'Rate Card', filter: true, filterType: 'text', name: 'rateInfo',
            customComponent: (row: VendorRaisedSubmissions) => {
                const chargeTypeKey = Object.entries(row.Charge_Type).find(([_, value]) => value)?.[0];
                const data = `${row.Bill_Rate}$ / ${chargeTypeKey === 'hourly' ? 'hour' : chargeTypeKey === 'weekly' ? 'week' : 'month'}`;
                return (<div>{data}</div>)
            }
        },
        { title: 'Counter Offer', filter: true, filterType: 'text', name: 'commission' },
        { title: 'Noof Profiles Uploaded', cellWidth: '230px', filter: true, filterType: 'number', name: 'No_of_Profiles' },
        { title: 'Status', filter: true, filterType: 'select', name: 'status', options: ['pending', 'accepted', 'rejected', 'interested', 'not-interested'] },
    ];

    const actionItmes = [
        {
            type: 'img', alt: 'preview', name: 'preview', src: '/Assets/Images/eye_open_vendor.svg', show: true, handleClick: (action: string, item: VendorRaisedSubmissions) => {
                setTabValue(3)
                setPreviewTabSubId(item?.C2C_Vendor_Bid_Id)
            }
        },
        {
            type: 'button', name: 'upload_resume', btnName: 'Add new CV', show: true, handleClick: (action: string, item: VendorRaisedSubmissions) => {
                setUploadCvSubId(item?.C2C_Vendor_Bid_Id || '')
                showModal(true)
            }
        }
    ]

    const handleChange = () => {
        setExpanded(!expanded);
    };

    function a11yProps(index: number) {
        return {
            id: `tab-${index}`,
            "aria-controls": `tab-panel-${index}`,
        };
    }

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 2) {
            refetch();
        }
    };

    const handleFormmatData = () => {
        const fetchedData = rfqDescription?.details.rfq_details;
        setDetails([
            {
                label: 'Visa Type / Work Permit',
                value: fetchedData?.visa_type && fetchedData?.visa_type.length > 0 ? fetchedData?.visa_type.join(', ') : '--',
                icon: '/Assets/Images/visa.svg'
            },
            {
                label: 'Employee Level',
                value: fetchedData?.employee_level || '--',
                icon: '/Assets/Images/userCheck.svg'
            },
            {
                label: 'Number of Positions',
                value: fetchedData?.no_of_positions ? fetchedData?.no_of_positions.toString() : '--',
                icon: '/Assets/Images/userPlus.svg'
            },
            {
                label: 'Employee Type',
                value: fetchedData?.job_type || '--',
                icon: '/Assets/Images/mathSymbols.svg'
            },
            {
                label: 'Requisition Closing Date',
                value: fetchedData?.rfq_closing_date || '--',
                icon: '/Assets/Images/calender.svg'
            },
            {
                label: 'Location',
                value: fetchedData?.locations && fetchedData?.locations.length > 0 ? fetchedData?.locations.join(', ') : '--',
                icon: '/Assets/Images/location.svg'
            },
            {
                label: 'Commission',
                value: fetchedData?.rate_info?.commission || '--',
                icon: '/Assets/Images/commission.svg'
            },
        ]);
    };

    const handleCloseTab = () => {
        setTabValue(2)
        setPreviewTabSubId('')
    }

    const handleSendSubmission = () => {
        let billRate = rfqDescription?.details?.rfq_details?.rate_info?.bill_rate?.split('/')[1]
        setSubmissionForm({
            visa_type: rfqDescription?.details?.rfq_details?.visa_type || [],
            Locations: rfqDescription?.details?.rfq_details?.locations || [],
            employeeLevel: rfqDescription?.details?.rfq_details?.employee_level || '',
            profiles: 0,
            rateType: billRate?.includes('hour') ? 'Hourly' : billRate?.includes('week') ? 'Weekly' : 'Monthly',
            commissionPreposedByEmployer: null,
            commission: 0,
            ratePerHour: 0,
            fileName: null
        })
    }

    const handleUploadMoreCvs = async () => {
        try {
            if ((uploadCvs?.length > 0 && uploadCvs?.length < 11) && declarationCheck) {
                const payload = {
                    sub_id: uploadCvSubId || '',
                    cv_File: uploadCvs || null
                }
                await updateVendorSubmissionCvs(payload)
            } else {
                toast.warning('Can not upload more than 10 CVs at a time')
                return
            }
        } catch (error) {
            toast.error('something went wrong please try again')
        } finally {
            setUploadCvs([]);
            setUploadCvSubId('');
            showModal(false)
        }
    }

    const handleCallCvApi = (updatedData: boolean) => {
        if (updatedData) previewTabRefetchData()
    };

    useEffect(() => {
        setSubmissionForm({
            visa_type: [],
            Locations: [],
            employeeLevel: '',
            profiles: 0,
            rateType: '',
            commissionPreposedByEmployer: null,
            commission: 0,
            ratePerHour: 0,
            fileName: null
        })
        if (rfqDescription) {
            handleFormmatData()
        } else if (error) {
            showSpinner(false)
        }
    }, [rfqDescription, tabValue]);

    useEffect(() => {
        if (raisedSubmissions?.length && !error) {
          const updatedSubmissions = raisedSubmissions.map((subItem) => {
            const chargeTypeKey = Object.entries(subItem?.Charge_Type).find(([_, value]) => value)?.[0];
            return {
              ...subItem,
              rateInfo: `${subItem.Bill_Rate}$ / ${
                chargeTypeKey === 'hourly' ? 'hour' : chargeTypeKey === 'weekly' ? 'week' : 'month'
              }`,
            };
          });
      
          // Sort the cloned and updated array
            const sorted = [...updatedSubmissions].sort((a, b) => b.created_at - a.created_at);      
          // Update state
          setSortedSubmissions(sorted);
          showSpinner(false);
        } else {
          showSpinner(false);
        }
      }, [raisedSubmissions, error]);
      

    useEffect(() => {
        if (tabValue === 3) {
            previewTabRefetchData()
        }
    }, [tabValue, previewTabSubId])

    return (
        <MainContainer>
            <PageHeader tooltip="RMP Detail page" backButton={true} navigationUrl={-1} backgroundColor={themeColors.white || ''} heading='Requisition Marketplace (RMP)' icon="/Assets/Images/vendorRmpDetailHeader.svg" />
            <Spinner show={isLoading || spinner}>
                <StyledCard>
                    <StyledHeader>
                        <StyledBox>
                            <StyledTypography variant="h3">
                                {rfqDescription?.details?.rfq_details?.job_title} <StyledText>Simple RFQ</StyledText><Button variant="outlined" disabled={tabValue !== 0} onClick={handleSendSubmission}>Smart Fill</Button>
                            </StyledTypography>
                        </StyledBox>
                        <StyledAccordionSummary
                            expandIcon={expanded ? <StyledKeyboardArrowUpOutlinedIcon /> : <StyledGridExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            onClick={handleChange}
                        />
                    </StyledHeader>
                    {(expanded) &&
                        <>
                            <SubContainer>
                                <StyledGridItem item md={6}>
                                    <StyledDownloadToolBadge>
                                        <StyledLink href={rfqDescription?.details?.rfq_details?.upload_job_description?.url} target="_blank">
                                            <img src="/Assets/Images/pdfIcon.svg" alt="" /> Open JD from here
                                        </StyledLink>
                                    </StyledDownloadToolBadge>
                                    <DetailsCard jobDetails={details[0]} />
                                    <DetailsCard jobDetails={details[1]} />
                                    <DetailsCard jobDetails={details[2]} />
                                </StyledGridItem>
                                <StyledGridItem item md={6}>
                                    <br /><br />
                                    <DetailsCard jobDetails={details[3]} />
                                    <DetailsCard jobDetails={details[4]} />
                                    <DetailsCard jobDetails={details[5]} />
                                    <DetailsCard jobDetails={details[6]} />
                                </StyledGridItem>
                                <StyledGridItem>
                                    <Typography variant="h4">
                                        Rate Card
                                    </Typography>
                                    <StyledCoin>
                                        <CoinImageTab>
                                            <img src={`/Assets/Images/${billRate?.includes('hour') ? 'Hour.svg' : billRate?.includes('week') ? 'Week.svg' : 'Month.svg'}`} alt="" />
                                            <CoinImageText>
                                                <Tooltip PopperProps={{ style: { zIndex: 0 } }} title={billRate?.split('$')[0]}><div style={{ cursor: 'pointer' }}>{billRate?.split('$')[0] ? (billRate?.split('$')[0]?.length > 4 ? `${billRate?.split('$')[0]?.slice(0, 4)}...` : billRate?.split('$')[0]) : 0}</div></Tooltip>
                                            </CoinImageText>
                                        </CoinImageTab>
                                    </StyledCoin>
                                </StyledGridItem>
                            </SubContainer>
                            <StyledSkills>
                                <Grid container>
                                    <Grid item md={3.9}>
                                        <StyledPrimarySkillsContainer>
                                            <StyledPrimarySkills><img src={'/Assets/Images/skillsPurple.svg'} height={25} width={25} alt={'primarySkills'} />Primary Skills</StyledPrimarySkills>
                                            <CardItems>
                                                <ToolsContainer>
                                                    {rfqDescription?.details?.rfq_details?.primary_skills?.map((tool, index) => (
                                                        <ToolBadge key={index}>{tool}</ToolBadge>
                                                    ))}
                                                </ToolsContainer>
                                            </CardItems>
                                        </StyledPrimarySkillsContainer>
                                    </Grid>
                                    <Grid item md={4}>
                                        <StyledPrimarySkillsContainer>
                                            <StyledPrimarySkills><img src={'/Assets/Images/skillsPurple.svg'} height={25} width={25} alt={'primarySkills'} />Secondary Skills</StyledPrimarySkills>
                                            <CardItems>
                                                <ToolsContainer>
                                                    {rfqDescription?.details?.rfq_details?.secondary_skills?.map((tool, index) => (
                                                        <ToolBadge key={index}>{tool}</ToolBadge>
                                                    ))}
                                                </ToolsContainer>
                                            </CardItems>
                                        </StyledPrimarySkillsContainer>
                                    </Grid>
                                    <Grid item md={4}>
                                        <StyledViewEmployeeDetails>
                                            <Button variant="outlined" onClick={() => { setShowEmployeeDetails(!showEmployeeDetails); setShowEmailIcon(false); setShowCompanyIcon(false) }}>
                                                <img src="/Assets/Images/lock.svg" alt="Lock Icon" /> View Employer Details
                                            </Button>
                                        </StyledViewEmployeeDetails>
                                        {showEmployeeDetails && (
                                            <StyledEmployeeCard>
                                                <Typography variant="h6" sx={{ background: themeColors?.purpleLight, padding: '3px' }}>Employee Details</Typography>
                                                <Typography>Employer ID : {rfqDescription?.AboutCompany?.user_id}</Typography>
                                                <Typography>Name: {(rfqDescription?.AboutCompany?.general_profile?.first_name || '') + (rfqDescription?.AboutCompany?.general_profile?.last_name || '')}</Typography>
                                                <Typography>Contact Number : {rfqDescription?.AboutCompany?.general_profile?.phone_number || '--'}</Typography>
                                                <Box sx={{ display: 'flex', gap: '3px' }}>
                                                    <Typography>Email: <a href={`mailto:${rfqDescription?.AboutCompany?.email && rfqDescription?.AboutCompany?.email}`} target="_blank">{rfqDescription?.AboutCompany?.email || '--'}</a></Typography>
                                                    {rfqDescription?.AboutCompany?.email &&
                                                        <CopyToClipboard
                                                            text={rfqDescription?.AboutCompany?.email}
                                                            onCopy={() => setShowEmailIcon(true)}
                                                        >
                                                            {!showEmailIcon ? <CopyIcon /> : <CheckCopy />}
                                                        </CopyToClipboard>
                                                    }
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: '3px' }}>
                                                    <Typography>Website : <a href={rfqDescription?.AboutCompany?.general_profile?.website_link && rfqDescription?.AboutCompany?.general_profile?.website_link} target="_blank">{rfqDescription?.AboutCompany?.general_profile?.website_link || '--'}</a></Typography>
                                                    {rfqDescription?.AboutCompany?.general_profile?.website_link &&
                                                        <CopyToClipboard
                                                            text={rfqDescription?.AboutCompany?.general_profile?.website_link}
                                                            onCopy={() => setShowCompanyIcon(true)}
                                                        >
                                                            {!showCompanyIcon ? <CopyIcon /> : <CheckCopy />}
                                                        </CopyToClipboard>
                                                    }
                                                </Box>
                                                <Typography align="right">
                                                    <Button variant="outlined" onClick={() => { setShowEmployeeDetails(!showEmployeeDetails); setShowEmailIcon(false); setShowCompanyIcon(false) }}>Close</Button>
                                                </Typography>
                                            </StyledEmployeeCard>
                                        )}
                                    </Grid>
                                </Grid>
                            </StyledSkills>
                        </>
                    }
                </StyledCard>


                <StyledContainer>
                    <StyledTabContainer>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab label="Submit Submission" {...a11yProps(0)} />
                                {/* <Tab label="Chat" {...a11yProps(1)} /> */}
                                <Tab label="Description" {...a11yProps(1)} />
                                <Tab label="Raised Submissions" {...a11yProps(2)} />
                                {(tabValue === 3 || previewTabSubId) && <Tab key={previewTabSubId} label={(<div style={{ display: 'flex', alignItems: 'center' }}>
                                    Sub ID: {previewTabSubId}
                                    {tabValue === 3 &&
                                        <IconButton
                                            size="small"
                                            onClick={handleCloseTab}
                                            style={{ marginLeft: '8px', color: themeColors.white }}
                                        >
                                            <GridCloseIcon fontSize="small" />
                                        </IconButton>
                                    }
                                </div>)} {...a11yProps(2)} />}
                            </Tabs>
                        </Box>
                        <StyledTabPannaels>
                            <SharedTabPannel value={tabValue} index={0}>
                                <SubmissionForm
                                    userId={userId} rfqId={rfqId}
                                    existingCommission={rfqDescription?.details.rfq_details.rate_info.commission}
                                    fullName={`${userDetails?.first_name} ${userDetails?.last_name}`}
                                    actualCommission={rfqDescription?.details?.rfq_details?.rate_info?.commission}
                                    formDetails={submissionForm}
                                />
                            </SharedTabPannel>
                            <SharedTabPannel value={tabValue} index={1}>
                                <VendorDescription
                                    jobTitle={rfqDescription?.details?.rfq_details?.job_title || ''}
                                    quickMessage={rfqDescription?.details?.rfq_details?.quick_message_to_vendor}
                                    jdIntro={rfqDescription?.description?.JDParsedData?.JobProfile?.Alias}
                                    jdRoles={rfqDescription?.description?.JDParsedData?.Responsibilities}
                                    primarySkills={rfqDescription?.details?.rfq_details?.primary_skills}
                                    secondrySkills={rfqDescription?.details?.rfq_details?.secondary_skills}
                                    minExp={rfqDescription?.details?.rfq_details?.experience[0]?.split(',')[0]}
                                    maxExp={rfqDescription?.details?.rfq_details?.experience[0]?.split(',')[1]}
                                    qualifications={rfqDescription?.details?.rfq_details?.qualifications}
                                />
                            </SharedTabPannel>
                            <SharedTabPannel value={tabValue} index={2} key={tabValue}>
                                <ReusableTable
                                    headers={headers}
                                    data={sortedSubmissions}
                                    itemsPerPage={10}
                                    spinner={RaisedSubmissionLoading}
                                    actions={actionItmes}
                                    inputBorder={themeColors.purpleDark}
                                    actionsCellWidth={'200px'}
                                    headerColor={themeColors.purpleLight}
                                />
                            </SharedTabPannel>
                            {tabValue === 3 &&
                                <SubmissionDetails
                                    submissionCvData={submissonCvList?.allCvs || []}
                                    submissionDetails={submissonCvList?.Details}
                                    spinner={getCvDataLoader}
                                    handleCallCvApi={handleCallCvApi}
                                />
                            }
                        </StyledTabPannaels>
                    </StyledTabContainer>
                </StyledContainer>
            </Spinner>

            <Modal
                open={modal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <StyledModalBody>
                    <Box sx={{ ...modalStyle }}>
                        <FileuploadBox>
                            <MultipleFileUpload
                                noUploadName='No File Uploaded'
                                onFileChange={async (fileBase64Array: string[] | null) => {
                                    if (fileBase64Array) {
                                        setUploadCvs(fileBase64Array);
                                    }
                                }}
                                resetKey={resetKey}
                            />
                        </FileuploadBox>
                        <Checkbox sx={{ margin: '0' }} checked={declarationCheck} onClick={() => setDeclarationCheck(!declarationCheck)} />I declare that I have full rights and ownership over the resumes submitted above
                        {uploadCvs?.length === 0 ?
                            <Typography color={themeColors.error}>Atleast one File is required to upload more CVs</Typography> : uploadCvs?.length > 10 &&
                            <Typography color={themeColors.error}>you can upload 10 files in a single stretch</Typography>
                        }
                        {
                            !declarationCheck && uploadCvs?.length > 0 &&
                            <Typography color={themeColors.error}>Please check the box to confirm you have rights to the resume.</Typography>
                        }
                        <Spinner show={cvUploadLoading}>
                            <Typography sx={{ marginTop: '10px' }} align={'right'}>
                                <Button sx={{ marginRight: '10px' }} variant="contained" size="small" disabled={uploadCvs?.length === 0 || !declarationCheck} onClick={handleUploadMoreCvs}>Upload Cvs</Button>
                                <Button variant="outlined" size="small" onClick={() => showModal(false)}>cancel</Button>
                            </Typography>
                        </Spinner>
                    </Box>
                </StyledModalBody>
            </Modal>
        </MainContainer>
    );
}

const CopyIcon = styled(ContentCopyIcon)({
    cursor: "pointer",
    color: themeColors.blackGray,
    marginLeft: "5px",
});

const CheckCopy = styled(LibraryAddCheckIcon)({
    cursor: "pointer",
    color: themeColors.success,
    marginLeft: "5px",
})

const StyledAccordionSummary = styled(AccordionSummary)({
    background: 'white'
})

const StyledViewEmployeeDetails = styled(Box)({
    position: "relative",
    textAlign: 'center',
});

const StyledEmployeeCard = styled(Card)({
    position: 'absolute',
    top: '460px',
    left: '80%',
    transform: 'translateX(-50%)',
    height: 'auto',
    width: '400px',
    zIndex: 1,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
});

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "15px",
    padding: '5px 20px 20px 20px'
};

const FileuploadBox = styled(Box)({
    marginTop: "16px",
    textAlign: "center",
    borderRadius: "10px",
    border: `1px dashed ${themeColors.emptyStar}`,
});

const StyledModalBody = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
}));

const StyledCard = styled(Card)({
    marginTop: '30px'
})

const StyledSkills = styled('div')({
    display: 'flex',
})

const StyledPrimarySkillsContainer = styled('div')({
    margin: '0',
    paddingLeft: '30px',
    marginBottom: '20px'
})

const StyledDownloadToolBadge = styled(Paper)({
    width: '100%',
    background: themeColors.purpleLight,
    color: themeColors.purpleDark,
    padding: '5px',
    marginBottom: '10px',
    textDecoration: 'none'
})

const StyledGridExpandMoreIcon = styled(GridExpandMoreIcon)({
    fontSize: '40px',
});

const StyledKeyboardArrowUpOutlinedIcon = styled(KeyboardArrowUpOutlinedIcon)({
    fontSize: '40px'
})

const StyledContainer = styled(Box)({
    marginBottom: '30px'
})

const StyledTabContainer = styled(Card)({
    marginTop: '12px',
    padding: '15px'
})

const StyledTabPannaels = styled(Box)({
    paddingTop: '20px'
})

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "10px"
})

const StyledPrimarySkills = styled(Typography)({
    display: 'flex',
    gap: '10px',
    fontSize: '20px',
    color: themeColors.purpleDark
})

const StyledLink = styled.a`
    display: flex;
    align-items: center;
    color: ${themeColors.purpleDark};
    text-decoration: none;
    font-size: inherit;
    gap: 10px;
    font-size: 16px
`;

const StyledHeader = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    borderRadius: '8px',
    justifyContent: 'space-between',
});

const ToolBadge = styled(Paper)({
    backgroundColor: themeColors.purpleLight,
    color: "black",
    boxShadow: "none",
    padding: "4px 8px",
    borderRadius: "9px",
    fontSize: "12px",
    display: "inline-block",
    margin: "4px",
});

const CardItems = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
});

const ToolsContainer = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    margin: "2px 0",
});

const StyledTypography = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

const StyledText = styled(Typography)({
    background: themeColors.purpleLight,
    color: themeColors.purpleDark,
    padding: '5px',
    borderRadius: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: 'bold'
});


const SubContainer = styled('div')({
    display: 'flex',
    gap: '30px',
    paddingTop: '30px',
    paddingLeft: '30px',
    paddingRight: '30px'
});

const MainContainer = styled('div')({
    margin: '0 30px',
    marginRight: '50px',
    paddingLeft: '30px',
    paddingRight: '30px',
    width: '95%',
    height: '100%',
    paddingTop: '24px',
    minHeight: '100vh',
    backgroundColor: '#FBFBFB',
    overflow: 'hidden'
});

const StyledGridItem = styled(Grid)({
    flexBasis: '38%',
    boxSizing: 'border-box',
});

const StyledCoin = styled(Card)({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    position: 'relative',
});

const CoinImageTab = styled('div')({
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: 'auto',
});

const CoinImageText = styled('div')({
    position: 'absolute',
    color: themeColors.purpleMoneyShade,
    fontSize: '20px',
    fontWeight: 'bold',
    bottom: '44%',
    left: '48%',
    transform: 'translateX(-50%)',
    width: '20%',
    lineHeight: '16px',
});

export default RmpDetails;
